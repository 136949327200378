import { createAction } from 'redux-unfold-saga';

export const AUTH_GET_INFO_USER_SSO = 'AUTH_GET_INFO_USER_SSO';
export const AUTH_SET_ACCESS_TOKEN = 'AUTH_SET_ACCESS_TOKEN';

export const AUTH_LOGOUT_APPLICATION = 'AUTH_LOGOUT_APPLICATION';
export const AUTH_SET_LIST_PERMISSION_V2 = 'AUTH_SET_LIST_PERMISSION_V2';

export const AUTH_SET_POST_OFFICE_SELECTED = 'AUTH_SET_POST_OFFICE_SELECTED';

export const REQUEST_OTP_LOGIN = 'REQUEST_OTP_LOGIN';
export const CONFIRM_OTP_LOGIN = 'CONFIRM_OTP_LOGIN';
export const AUTH_OTP_LOGIN_WITH_PHONE = 'AUTH_OTP_LOGIN_WITH_PHONE';
export const AUTH_GET_POST_BY_USER = 'AUTH_GET_POST_BY_USER';
export const AUTH_CHANGE_POST_OFFICE_USER = 'AUTH_CHANGE_POST_OFFICE_USER';
export const AUTH_GET_LIST_USER = 'AUTH_GET_LIST_USER';

export const AUTH_GET_ROLE_BY_USER = 'AUTH_GET_ROLE_BY_USER';
//dăng ký
export const AUTH_REGISTER_REQUEST = 'AUTH_REGISTER_REQUEST';
export const AUTH_REGISTER_SUCCESS = 'AUTH_REGISTER_SUCCESS';
export const AUTH_REGISTER_FAILURE = 'AUTH_REGISTER_FAILURE';

//DANG NHAP
export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAILURE = 'AUTH_LOGIN_FAILURE';


export const actionAuthGetInfoUserSSO = createAction(AUTH_GET_INFO_USER_SSO);
export const actionAuthSetAccessToken = createAction(AUTH_SET_ACCESS_TOKEN);
export const actionAuthGetUserAll = createAction(AUTH_GET_LIST_USER);

export const actionAuthLogoutApplication = createAction(AUTH_LOGOUT_APPLICATION);
export const actionAuthSetSelectedPO = createAction(AUTH_SET_POST_OFFICE_SELECTED);
export const actionAuthSetListPermissionV2 = createAction(AUTH_SET_LIST_PERMISSION_V2);

export const actionAuthGetRoleByUser = createAction(AUTH_GET_ROLE_BY_USER);

export const actionAuthRequestOtpLogin = createAction(REQUEST_OTP_LOGIN);
export const actionAuthConfirmOtpLOgin = createAction(CONFIRM_OTP_LOGIN);
export const actionAuthLoginWithPhone = createAction(AUTH_OTP_LOGIN_WITH_PHONE);
export const actionAuthGetPostByUser = createAction(AUTH_GET_POST_BY_USER);
export const actionAuthChangePostUser = createAction(AUTH_CHANGE_POST_OFFICE_USER);

export const actionAuthRegisterRequest = createAction(AUTH_REGISTER_REQUEST);//req đăng ký

//dang nhap thuong
export const actionAuthLoginRequest = createAction(AUTH_LOGIN_REQUEST);
export const actionAuthLoginSuccess = createAction(AUTH_LOGIN_SUCCESS);
export const loginSuccess = (data: any) => ({type: AUTH_LOGIN_SUCCESS,payload: data});





