/* eslint-disable react/display-name */
/* eslint-disable @typescript-eslint/ban-types */
import { Alert } from 'antd';
import React, { ForwardedRef, forwardRef } from 'react';

const { ErrorBoundary } = Alert;

function withErrorBoundary<P extends {}, R extends {}>(Component: React.ComponentType<P>) {
  return forwardRef((props: P, ref: ForwardedRef<R>) => (
    <ErrorBoundary description="">
      <Component {...props} ref={ref} />
    </ErrorBoundary>
  ));
}

export default withErrorBoundary;
