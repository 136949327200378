import { RoleAppUser } from 'common/phanQuyen';
import { AppRouteType } from 'layouts/helper';
import pagesMap from 'layouts/pagesMap';
import routesMap from 'layouts/routesMap';

export const smartOderRoute: AppRouteType[] = [
  {
    path: routesMap.PROVINCE,
    component: pagesMap.Province,
    rule: RoleAppUser.CATEGORY_CONFIG,
  },
  {
    path: routesMap.DISTRICT,
    component: pagesMap.District,
    rule: RoleAppUser.CATEGORY_CONFIG,
  },
  {
    path: routesMap.WARD,
    component: pagesMap.Ward,
    rule: RoleAppUser.CATEGORY_CONFIG,
  },
  {
    path: routesMap.ICE_LEVEL,
    component: pagesMap.IceLevel,
    rule: RoleAppUser.CATEGORY_CONFIG,
  },
  {
    path: routesMap.SUGAR_LEVEL,
    component: pagesMap.SugarLevel,
    rule: RoleAppUser.CATEGORY_CONFIG,
  },
  {
    path: routesMap.SIZE_LEVEL,
    component: pagesMap.SizeLevel,
    rule: RoleAppUser.CATEGORY_CONFIG,
  },
  {
    path: routesMap.TOPPING,
    component: pagesMap.Topping,
    rule: RoleAppUser.CATEGORY_CONFIG,
  },
  {
    path: routesMap.TEA_LEVEL,
    component: pagesMap.TeaLevel,
    rule: RoleAppUser.CATEGORY_CONFIG,
  },
  {
    path: routesMap.JOB,
    component: pagesMap.Job,
    rule: RoleAppUser.CATEGORY_CONFIG,
  },
  {
    path: routesMap.BUSSINESS_TYPE,
    component: pagesMap.BussinessType,
    rule: RoleAppUser.CATEGORY_CONFIG,
  },
  {
    path: routesMap.UNIT,
    component: pagesMap.Unit,
    rule: RoleAppUser.CATEGORY_CONFIG,
  },
  {
    path: routesMap.MERCHANDISE_MANAGEMENT_LIST_ITEM,
    component: pagesMap.DSMatHang,
    rule: RoleAppUser.QUANLY_DS_MATHANG,
  },
  {
    path: routesMap.MERCHANDISE_MANAGEMENT_CATEGORY_ITEM,
    component: pagesMap.DMMatHang,
    rule: RoleAppUser.QUANLY_DM_MATHANG,
  },
  {
    path: routesMap.MERCHANDISE_MANAGEMENT_GROUP_ITEM,
    component: pagesMap.NhomMatHang,
    rule: RoleAppUser.QUANLY_NHOM_MATHANG,
  },
  {
    path: routesMap.MERCHANDISE_MANAGEMENT_MENU,
    component: pagesMap.MenuMatHang,
    rule: RoleAppUser.QUANLY_THUCDON,
  },
  {
    path: routesMap.MERCHANDISE_MANAGEMENT_COMBO,
    component: pagesMap.Combo,
    rule: RoleAppUser.QUANLY_COMBO,
  },

];
