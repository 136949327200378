import { groupBy } from 'lodash';
import { SagaIterator } from 'redux-saga';
import { all, call } from 'redux-saga/effects';
import AuthSaga from 'redux/auth/saga';
import GroupItemSaga from 'redux/category/group-item/saga';
import IceLevelSaga from 'redux/category/ice-level/saga';
import MenuSaga from 'redux/category/menu/saga';
import SugarLevelSaga from 'redux/category/sugar-level/saga';
import SizeLevelSaga from 'redux/category/size-level/saga';
import TeaLevelSaga from 'redux/category/tea-level/saga';
import ToppingLevelSaga from 'redux/category/topping-level/saga';

export const hasDuplicated = (array: (() => SagaIterator)[]) => {
  const nameGroup = groupBy(array.map((element) => element.name));
  return Object.keys(nameGroup).find((name) => nameGroup[name].length > 1);
};

export default function* rootSagas() {
  const sagas = [AuthSaga, IceLevelSaga, SugarLevelSaga, SizeLevelSaga, TeaLevelSaga, ToppingLevelSaga,MenuSaga,GroupItemSaga];

  const nameExists = hasDuplicated(sagas);
  if (nameExists) {
    throw new Error(`Trùng saga: ${nameExists}`);
  }

  yield all(sagas.map((saga) => call(saga)));
}
