import { init as initApm } from '@elastic/apm-rum';
import { ConfigProvider } from 'antd';
import viVN from 'antd/lib/locale/vi_VN';
import { store } from 'app/store';
import ErrorBoundary from 'components/ErrorBoundary';
import LoadingSkeleton from 'components/LoadingSkeleton';
import AppContainer from 'layouts/AppContainer';
import moment from 'moment';
import 'moment/locale/vi';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FONT_FAMILY } from 'utils/constants';
import { REACT_APP_PRODUCT, REACT_APP_VERSION } from 'utils/env';
import './App.less';
import './App.scss';

moment.locale('vi');

export const apm = initApm({
  active: REACT_APP_PRODUCT, // chỉ active khi ở production
  serviceName: 'fico-portal-web',
  serviceVersion: '1.0.0',
  logLevel: 'error',
  environment: 'production',
  serverUrl: 'https://apm-public.viettelpost.vn',
  distributedTracingOrigins: ['https://fico.viettelpost.vn'],
});

const App: React.FC = () => {
  useEffect(() => {
    const body = document?.querySelector('body');
    if (body) {
      body.setAttribute('app-version', REACT_APP_VERSION);
    }
  }, []);

  return (
    <ConfigProvider locale={viVN}>
      <ErrorBoundary>
        <Provider store={store}>
          <React.Suspense fallback={<LoadingSkeleton />}>
            <BrowserRouter>
              <Routes>
                <Route path="/*" element={<AppContainer />} />
              </Routes>
            </BrowserRouter>
          </React.Suspense>
        </Provider>
        <ToastContainer autoClose={3500} style={{ fontFamily: FONT_FAMILY }} />
      </ErrorBoundary>
    </ConfigProvider>
  );
};

export default App;
