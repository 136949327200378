import { useAppDispatch } from 'app/hooks';
import routesMap from 'layouts/routesMap';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// import {
//   action_BKCP_FILTER_BANGKE_C2_CLEAN,
//   action_BKCP_FILTER_BANGKE_CLEAN,
// } from 'redux/KeKhaiChiPhi/BangKeChiPhi/actions';
// import {
//   action_BKTH_FILTER_BANGKE_C2_CLEAN,
//   action_BKTH_FILTER_BANGKE_C3_CLEAN,
// } from 'redux/KeKhaiChiPhi/BangTongHop/actions';
// import {
//   action_KKCP_FILTER_CLEAR_CLEAN,
//   action_KKCP_FILTER_CLEAR_CLEAN_HD,
//   action_KKCP_FILTER_INVOICE_CLEAN,
// } from 'redux/KeKhaiChiPhi/KeKhaiChiPhi/actions';

const AppReady: React.FC = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  useEffect(() => {
    // if (!location.pathname.includes(routesMap.CPTX_KEKHAI_DONVI)) {
    //   dispatch(action_KKCP_FILTER_INVOICE_CLEAN());
    //   dispatch(action_KKCP_FILTER_CLEAR_CLEAN_HD());
    // }
    // if (
    //   !location.pathname.includes(routesMap.CPTX_KEKHAI_CNHANH) &&
    //   !location.pathname.includes(routesMap.CPTX_KEKHAI_PBAN)
    // ) {
    //   dispatch(action_BKCP_FILTER_BANGKE_C2_CLEAN());
    //   dispatch(action_BKCP_FILTER_BANGKE_CLEAN()); // 2 cái này đang xài chùng và gây ra tình trạng xài chung initValue
    //   dispatch(action_KKCP_FILTER_CLEAR_CLEAN()); // 2 cái này đang xài chùng và gây ra tình trạng xài chung initValue
    // }
    // if (!location.pathname.includes(routesMap.CPTX_KEKHAI_TCT)) {
    //   dispatch(action_BKTH_FILTER_BANGKE_C2_CLEAN());
    //   dispatch(action_BKTH_FILTER_BANGKE_C3_CLEAN());
    // }
  }, [dispatch, location.pathname]);

  return null;
};

export default AppReady;
