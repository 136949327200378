/* eslint-disable @typescript-eslint/no-explicit-any */
import { smartOrderRequest } from 'api/createRequest';
import axios, { AxiosError } from 'axios';
import { FICO_PATH } from 'api/ficoPath';
import { LOGIN_PATH } from 'api/loginPath';
import { REGISTER_PATH } from 'api/registerPath';
import routesMaps from 'layouts/routesMap';
import { get } from 'lodash';
import { Saga, SagaIterator } from 'redux-saga';
import { put, takeLatest } from 'redux-saga/effects';
import { UnfoldSagaActionExecutionType, UnfoldSagaActionType, unfoldSaga } from 'redux-unfold-saga';
import { removeAllStorage } from 'utils/storage';
import Constants from 'config/constants/Constants';
import {
  AUTH_CHANGE_POST_OFFICE_USER,
  AUTH_GET_LIST_USER,
  AUTH_GET_POST_BY_USER,
  AUTH_GET_ROLE_BY_USER,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGOUT_APPLICATION,
  AUTH_OTP_LOGIN_WITH_PHONE,
  AUTH_REGISTER_REQUEST,
  CONFIRM_OTP_LOGIN,
  REQUEST_OTP_LOGIN,
  actionAuthLoginSuccess,
  loginSuccess,
} from './actions';

function* logoutApplication(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async (): Promise<any> => {
        window.location.href = `${routesMaps.LOGIN}`;
        removeAllStorage();

        return Promise.resolve();
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* requestOtpLoginSaga(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async (): Promise<any> => {
        const { data } = await smartOrderRequest.post(LOGIN_PATH.getOTPLogin, action.payload);
        if (!!data?.error) {
          throw new Error(data?.message);
        }
        return data;
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* confirmOtpLoginSaga(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async (): Promise<any> => {
        const { data } = await smartOrderRequest.post(LOGIN_PATH.confirmOTPLogin, action.payload);
        if (!!data?.error) {
          throw new Error(data?.message);
        }
        return data;
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* loginWithPhoneSaga(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async (): Promise<any> => {
        const { data } = await smartOrderRequest.post(LOGIN_PATH.loginWithPhone, action.payload.data, {
          headers: {
            'x-access-token': get(action.payload, 'token'),
          },
        });
        if (!!data?.error) {
          throw new Error(data?.message);
        }
        return data?.data;
      },
      key: action.type,
    },
    action.callbacks
  );
}

// function* registerSaga(action: UnfoldSagaActionType) :Iterable<SagaIterator>{
//   yield unfoldSaga(
//     {
//       handler: async (): Promise<any> => {
//         const  data  = await axios.post(`https://gateway.confmin.com/auth/merchant/register`, action.payload);
//         console.log(data," data check register")
//         // if (!!data?.error) {
//         //   throw new Error(data?.message);
//         // }
//         return data;
//       },
//       key: action.type,
//     },
//     action.callbacks
//   );
// }
function* registerSaga(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async (): Promise<any> => {
        const response = await smartOrderRequest.post(REGISTER_PATH.registerMerchant, action.payload);
        return response.data;
      },
      key: action.type,
    },
    action.callbacks
  );
}
function* loginSaga(action: UnfoldSagaActionType): SagaIterator {
  yield unfoldSaga(
    {
      handler: async (): Promise<any> => {
        const { data } = await smartOrderRequest.post(LOGIN_PATH.loginMerchant, action.payload);
        if (!Constants.keyValue.ERROR_CODES_SUCCESS.includes(data?.code)) {
          throw new Error(data?.message);
        }
        return data;
      },
      key: action.type,
    },
    action.callbacks
  );
  // yield put(loginSuccess(rs));
}

function* getPostByUserSaga(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async (): Promise<any> => {
        const { data } = await smartOrderRequest.post(LOGIN_PATH.getPostByUser, action.payload);
        if (!data?.error) {
          return data.data || [];
        }
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* changePostUserSaga(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async (): Promise<any> => {
        const { data } = await smartOrderRequest.post(LOGIN_PATH.changePost, action.payload);
        if (!!data?.error) {
          throw new Error(data?.message);
        }
        return data?.data;
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* getRoleByUser(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async (): Promise<any> => {
        const { data } = await smartOrderRequest.get(FICO_PATH.postModule, { params: action.payload });
        if (!!data?.error) {
          throw new Error(data?.message);
        }
        return data?.data;
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* getAllUser(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async (): Promise<any> => {
        const { data } = await smartOrderRequest.get(FICO_PATH.listUserAll, { params: action.payload });
        if (!!data?.error) {
          throw new Error(data?.message);
        }
        return data?.data;
      },
      key: action.type,
    },
    action.callbacks
  );
}

export default function* AuthSaga(): SagaIterator {
  yield takeLatest(AUTH_LOGOUT_APPLICATION, logoutApplication);

  yield takeLatest(REQUEST_OTP_LOGIN, requestOtpLoginSaga);
  yield takeLatest(CONFIRM_OTP_LOGIN, confirmOtpLoginSaga);
  yield takeLatest(AUTH_OTP_LOGIN_WITH_PHONE, loginWithPhoneSaga);
  yield takeLatest(AUTH_GET_POST_BY_USER, getPostByUserSaga);
  yield takeLatest(AUTH_CHANGE_POST_OFFICE_USER, changePostUserSaga);

  yield takeLatest(AUTH_GET_ROLE_BY_USER, getRoleByUser);
  yield takeLatest(AUTH_GET_LIST_USER, getAllUser);
  //register
  yield takeLatest(AUTH_REGISTER_REQUEST, registerSaga);
  //Login
  yield takeLatest(AUTH_LOGIN_REQUEST, loginSaga);
}
