import { combineReducers } from '@reduxjs/toolkit';
import authReducer from 'redux/auth/reducer';
import iceLevelReducer from 'redux/category/ice-level/reducer';
import sugarLevelReducer from 'redux/category/sugar-level/reducer';
import sizeLevelReducer from 'redux/category/size-level/reducer';
import teaLevelReducer from 'redux/category/tea-level/reducer';
import toppingLevelReducer from 'redux/category/topping-level/reducer';

const createRootReducer = () => {
  return combineReducers({
    auth: authReducer,
    iceLevel: iceLevelReducer,
    sugarLevel: sugarLevelReducer,
    sizeLevel: sizeLevelReducer,
    teaLevel: teaLevelReducer,
    toppingLevel: toppingLevelReducer,
  });
};

export default createRootReducer;
