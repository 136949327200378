/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from 'immer';
import { AppAction } from 'utils/types';

const initState: any = {
  listModuleApp: [],
};

const SugarLevelReducer = (state = initState, action: AppAction): any => {
  return produce(state, (draftState: any) => {
    switch (action.type) {
      default:
        return draftState;
    }
  });
};

export default SugarLevelReducer;
