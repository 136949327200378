import { lazy } from 'react';
import { delayLazyLoad, warpTransactionApm } from './helper';

const TrangChu = warpTransactionApm(
  'TrangChu',
  lazy(() => delayLazyLoad(import('features/TrangChu')))
);

const DangNhap = warpTransactionApm(
  'DangNhap',
  lazy(() => delayLazyLoad(import('features/DangNhap')))
);
const DangKy = warpTransactionApm(
  'DangKy',
  lazy(() => delayLazyLoad(import('features/DangKy')))
);

const Province = warpTransactionApm(
  'Province',
  lazy(() => delayLazyLoad(import('features/category/province')))
);
const District = warpTransactionApm(
  'District',
  lazy(() => delayLazyLoad(import('features/category/district')))
);
const Ward = warpTransactionApm(
  'Ward',
  lazy(() => delayLazyLoad(import('features/category/ward')))
);
const IceLevel = warpTransactionApm(
  'IceLevel',
  lazy(() => delayLazyLoad(import('features/category/ice-level')))
);

const SugarLevel = warpTransactionApm(
  'SugarLevel',
  lazy(() => delayLazyLoad(import('features/category/sugar-level')))
);
const SizeLevel = warpTransactionApm(
  'SizeLevel',
  lazy(() => delayLazyLoad(import('features/category/size-level')))
);
const Topping = warpTransactionApm(
  'Topping',
  lazy(() => delayLazyLoad(import('features/category/topping')))
);
const TeaLevel = warpTransactionApm(
  'TeaLevel',
  lazy(() => delayLazyLoad(import('features/category/tea-level')))
);
const Job = warpTransactionApm(
  'Job',
  lazy(() => delayLazyLoad(import('features/category/job')))
);
const BussinessType = warpTransactionApm(
  'BussinessType',
  lazy(() => delayLazyLoad(import('features/category/bussiness-type')))
);
const Unit = warpTransactionApm(
  'Unit',
  lazy(() => delayLazyLoad(import('features/category/unit')))
);
//****************************** */
const DSMatHang= warpTransactionApm(
  'DSMatHang',
  lazy(() => delayLazyLoad(import('features/QuanLyMatHang/DanhSachMatHang')))
);
const DMMatHang= warpTransactionApm(
  'DMMatHang',
  lazy(() => delayLazyLoad(import('features/QuanLyMatHang/DanhMucMatHang')))
);
const NhomMatHang= warpTransactionApm(
  'NhomMatHang',
  lazy(() => delayLazyLoad(import('features/QuanLyMatHang/NhomMatHang')))
);
const MenuMatHang= warpTransactionApm(
  'MenuMatHang',
  lazy(() => delayLazyLoad(import('features/QuanLyMatHang/ThucDon')))
);
const Combo= warpTransactionApm(
  'Combo',
  lazy(() => delayLazyLoad(import('features/QuanLyMatHang/Combo')))
);
//****************************** */
const pagesMap = {
  TrangChu,
  DangNhap,
  DangKy,
  //danh mục
  Province,
  District,
  Ward,
  IceLevel,
  SugarLevel,
  SizeLevel,
  Topping,
  TeaLevel,
  Job,
  BussinessType,
  Unit,
  //
  DSMatHang,
  DMMatHang,
  NhomMatHang,
  MenuMatHang,
  Combo
};

export default pagesMap;
