/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */

const breadcrumb: any = {
  province: ['Danh mục', 'Tỉnh'],
};

const keyValue = {
  ERROR_CODE: 0,
  ERROR_CODES_SUCCESS: [0, 1000, 10000],
};
export default {
  breadcrumb,
  keyValue,
};
