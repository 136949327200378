import { smartOrderRequest } from 'api/createRequest';
import { FICO_PATH } from 'api/ficoPath';
import Constants from 'config/constants/Constants';
import { SagaIterator } from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';
import { UnfoldSagaActionType, unfoldSaga } from 'redux-unfold-saga';
import { ACTION_GET_SUGAR_LEVEL, ACTION_SAVE_SUGAR_LEVEL, ACTION_DELETE_SUGAR_LEVEL } from './actions';

function* getSugarLevel(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        const { data } = await smartOrderRequest.get<BaseResponse>(FICO_PATH.sugarPath);
        if (!Constants.keyValue.ERROR_CODES_SUCCESS.includes(data?.code)) throw new Error(data?.message);

        return data?.data || [];
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* saveSugarLevel(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        const { data } = action.payload?.id
          ? await smartOrderRequest.put<BaseResponse>(FICO_PATH.sugarPathUpdate(action.payload?.id), action.payload)
          : await smartOrderRequest.post<BaseResponse>(FICO_PATH.sugarPath, action.payload);

        if (!Constants.keyValue.ERROR_CODES_SUCCESS.includes(data?.code)) throw new Error(data?.message);
        return data?.data;
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* deleteSugarLevel(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        const { data } = await smartOrderRequest.delete<BaseResponse>(FICO_PATH.sugarPathUpdate(action.payload?.id));
        if (!Constants.keyValue.ERROR_CODES_SUCCESS.includes(data?.code)) throw new Error(data?.message);

        return data?.data || {};
      },
      key: action.type,
    },
    action.callbacks
  );
}

export default function* SugarLevelSaga(): SagaIterator {
  yield takeEvery(ACTION_GET_SUGAR_LEVEL, getSugarLevel);
  yield takeEvery(ACTION_SAVE_SUGAR_LEVEL, saveSugarLevel);
  yield takeEvery(ACTION_DELETE_SUGAR_LEVEL, deleteSugarLevel);
}
