import { Modal } from 'antd';
import { store } from 'app/store';
import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import QueryString from 'qs';
import { actionAuthLogoutApplication } from 'redux/auth/actions';
import { injectEncrypt } from 'utils/encrypt';
import { REACT_APP_API_SMART_ORDER, REACT_APP_ENV } from 'utils/env';
import { STORAGE_KEY, getDataStorage } from 'utils/storage';
import Constants from 'config/constants/Constants';

// axios.defaults.timeout = 1000 * 60 * 30; //30 minutes
axios.defaults.timeoutErrorMessage = 'Mất kết nối đến máy chủ. Vui lòng thử lại';
axios.defaults.paramsSerializer = { serialize: (params) => QueryString.stringify(params, { indices: false }) };

const configure = (config: InternalAxiosRequestConfig) => {
  let accessToken = '';

  if (REACT_APP_ENV === 'development') {
    accessToken = getDataStorage(STORAGE_KEY.ACCESS_TOKEN);
  } else {
    accessToken = store.getState().auth.accessToken;
  }

  config.headers.set('Authorization', `Bearer ${accessToken}`);

  return injectEncrypt(config);
};

let flagLogout = true;
const configureRes = (response: AxiosResponse<BaseResponse>) => {
  const data = response.data;
  if (data.error && data.data?.errorCode === 52 && flagLogout) {
    flagLogout = false;
    Modal.confirm({
      width: 450,
      okText: 'Đồng ý',
      title: 'Thông báo',
      content: 'Phiên đăng nhập đã hết hạn. Vui lòng đăng nhập lại',
      onCancel: () => {
        flagLogout = true;
      },
      onOk: () => {
        store.dispatch(actionAuthLogoutApplication());
      },
    });
    return Promise.resolve(response);
  }
  return Promise.resolve(response);
};

const configureError = (error: AxiosError<BaseResponse>) => {
  if (error.response?.data) {
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error);
};

/** Request API LOGIN TMS */
// export const loginRequest = axios.create({
//   baseURL: REACT_APP_API_LOGIN_TMS,
// });

// loginRequest.interceptors.request.use(
//   (config) => configureLogin(config),
//   (error) => Promise.reject(error)
// );

/** -------------- Request API SAP -------------- */
// export const sapRequest = axios.create({
//   baseURL: REACT_APP_API_SAP_URL,
// });

// sapRequest.interceptors.request.use(
//   (config) => configure(config),
//   (error) => Promise.reject(error)
// );

/** -------------- Request API Fico Payment -------------- */
// export const ficoRequest = axios.create({
//   // timeout: 31000,
//   baseURL: REACT_APP_API_FICO_PAYMENT,
// });

// ficoRequest.interceptors.request.use(
//   (config) => configure(config),
//   (error) => Promise.reject(error)
// );
// ficoRequest.interceptors.response.use(
//   (response) => configureRes(response),
//   (error) => configureError(error)
// );

/** -------------- Request API Fico Payment Bank  --------------*/
// export const ficoBankRequest = axios.create({
//   // timeout: 31000,
//   baseURL: REACT_APP_API_FICO_PAYMENT_BANK,
// });

// ficoBankRequest.interceptors.request.use(
//   (config) => configure(config),
//   (error) => Promise.reject(error)
// );
// ficoBankRequest.interceptors.response.use(
//   (response) => configureRes(response),
//   (error) => configureError(error)
// );

/** -------------- Request API Kê khai chi phí -------------- */
// export const kkcpRequest = axios.create({
//   baseURL: REACT_APP_API_KE_KHAI_CHI_PHI,
// });

// kkcpRequest.interceptors.request.use(
//   (config) => configure(config),
//   (error) => Promise.reject(error)
// );
// kkcpRequest.interceptors.response.use(
//   (response) => configureRes(response),
//   (error) => configureError(error)
// );

// /** -------------- Request API Đối soát -------------- */
// export const doiSoatRequest = axios.create({
//   baseURL: REACT_APP_API_FICO_DOI_SOAT,
// });

// doiSoatRequest.interceptors.request.use(
//   (config) => configure(config),
//   (error) => Promise.reject(error)
// );
// doiSoatRequest.interceptors.response.use(
//   (response) => configureRes(response),
//   (error) => configureError(error)
// );

// /** -------------- Request API LOCATION -------------- */
// export const locationRequest = axios.create({
//   baseURL: REACT_APP_API_FICO_LOCATION,
// });

// locationRequest.interceptors.request.use(
//   (config) => configure(config),
//   (error) => Promise.reject(error)
// );
// locationRequest.interceptors.response.use(
//   (response) => configureRes(response),
//   (error) => configureError(error)
// );

// /** -------------- Request API LOCATION -------------- */
// export const mediaRequest = axios.create({
//   baseURL: REACT_APP_API_FICO_UPLOAD,
// });

// mediaRequest.interceptors.request.use(
//   (config) => configure(config),
//   (error) => Promise.reject(error)
// );
// mediaRequest.interceptors.response.use(
//   (response) => configureRes(response),
//   (error) => configureError(error)
// );

/** -------------- Request API LOCATION -------------- */
export const smartOrderRequest = axios.create({
  baseURL: REACT_APP_API_SMART_ORDER,
});

smartOrderRequest.interceptors.request.use(
  (config) => configure(config),
  (error) => Promise.reject(error)
);
smartOrderRequest.interceptors.response.use(
  (response) => configureRes(response),
  (error) => configureError(error)
);
