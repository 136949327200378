import LoadingSkeleton from 'components/LoadingSkeleton';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { Waiter } from 'react-wait';

const PublicLayout: React.FC = () => {
  return (
    <React.Suspense fallback={<LoadingSkeleton />}>
      <Waiter>
        <Outlet />
      </Waiter>
    </React.Suspense>
  );
};

export default PublicLayout;
export { useRoutes } from './useRoutes';
