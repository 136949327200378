import { smartOrderRequest } from 'api/createRequest';
import { FICO_PATH } from 'api/ficoPath';
import { SagaIterator } from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';
import { UnfoldSagaActionType, unfoldSaga } from 'redux-unfold-saga';
import { ACTION_GET_MENU, ACTION_SAVE_MENU, ACTION_DELETE_MENU } from './actions';

function* getMenu(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        const payload=action.payload
        const { data } = await smartOrderRequest.get<BaseResponse>(FICO_PATH.menuPath);
        if (data?.error) throw new Error(data?.message);

        return data?.data || [];
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* saveMenu(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        const { data } = action.payload?.id
          ? await smartOrderRequest.put<BaseResponse>(FICO_PATH.icePathUpdate(action.payload?.id), action.payload)
          : await smartOrderRequest.post<BaseResponse>(FICO_PATH.menuPath, action.payload);

        if (Boolean(data?.error)) throw new Error(data?.message);
        return data?.data;
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* deleteMenu(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        const { data } = await smartOrderRequest.delete<BaseResponse>(FICO_PATH.icePathUpdate(action.payload?.id));
        if (data?.error) throw new Error(data?.message);

        return data?.data || {};
      },
      key: action.type,
    },
    action.callbacks
  );
}

export default function* MenuSaga(): SagaIterator {
  yield takeEvery(ACTION_GET_MENU, getMenu);
  yield takeEvery(ACTION_SAVE_MENU, saveMenu);
  yield takeEvery(ACTION_DELETE_MENU, deleteMenu);
}
