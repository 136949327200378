import { AppRouteType } from 'layouts/helper';
import pagesMap from 'layouts/pagesMap';
import routesMap from 'layouts/routesMap';
import { useMemo } from 'react';

const routes: AppRouteType[] = [
  {
    path: routesMap.LOGIN,
    component: pagesMap.DangNhap,
    rule: -1,
  },
  {
    path: routesMap.REGISTER,
    component: pagesMap.DangKy,
    rule: -1,
  },
];

export function useRoutes() {
  return useMemo(() => routes, []);
}
