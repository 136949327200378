import { ROLE_CONFIG, RoleAppUser } from 'common/phanQuyen';
import { NavLinkItem, hasNavPermission } from 'layouts/helper';
import routesMap from 'layouts/routesMap';
import { useMemo } from 'react';
import { BsCash } from 'react-icons/bs';
import { FaRegChartBar, FaUsersCog } from 'react-icons/fa';
import {
  FiBarChart2,
  FiBook,
  FiClipboard,
  FiCreditCard,
  FiHome,
  FiMic,
  FiRepeat,
  FiSearch,
  FiTrendingUp,
} from 'react-icons/fi';
import { REACT_APP_PRODUCT } from 'utils/env';

const navs: Array<NavLinkItem> = [
  {
    name: 'Trang chủ',
    key: routesMap.HOME,
    icon: FiHome,
    rule: -1,
  },
  // {
  //   name: 'Quản trị hệ thống',
  //   key: routesMap.QUANTRI_HETHONG,
  //   rule: RoleAppUser.QUANTRI_HETHONG,
  //   icon: FaUsersCog,
  //   items: [
  //     {
  //       name: 'Cấu hình',
  //       key: 'CAU_HINH_GROUP',
  //       rule: '',
  //       labelGroup: true,
  //       items: [
  //         {
  //           name: 'Quản lý chức năng',
  //           key: routesMap.QUANLY_CHUCNANG,
  //           rule: RoleAppUser.QUANLY_CHUCNANG,
  //         },
  //         // {
  //         //   name: 'Quản lý cấu hình',
  //         //   key: routesMap.QUANLY_CAUHINH,
  //         //   rule: -1,
  //         // },
  //         {
  //           name: 'Cấu hình on/off chức năng',
  //           key: routesMap.CAUHINH_ONOFF_CHUCNANG,
  //           rule: RoleAppUser.CAUHINH_ONOFF_CHUCNANG,
  //         },
  //         {
  //           name: 'Cấu hình upload media',
  //           key: routesMap.CAUHINH_UPLOAD_MEDIA,
  //           rule: RoleAppUser.CAUHINH_UPLOAD_MEDIA,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Quản lý',
  //       key: 'QUAN_LY_GROUP',
  //       rule: '',
  //       labelGroup: true,
  //       items: [
  //         {
  //           name: 'Danh mục khách hàng hệ thống',
  //           key: routesMap.DANHMUC_KHACHHANG_HETHONG,
  //           rule: RoleAppUser.DANHMUC_KHACHHANG_HETHONG,
  //         },
  //         {
  //           name: 'Công nợ nội bộ - Khai báo danh mục',
  //           key: routesMap.KHAIBAO_HANMUC_CONGNO,
  //           rule: RoleAppUser.KHAIBAO_HANMUC_CONGNO,
  //         },
  //         {
  //           name: 'Quản lý chi phí - Cấu hình lùi ngày',
  //           key: routesMap.CAUHINH_LUINGAY_QLCP,
  //           rule: RoleAppUser.CAUHINH_LUINGAY_QLCP,
  //         },
  //         {
  //           name: 'Cấu hình thời gian thanh toán',
  //           key: routesMap.CAUHINH_THOIGIAN_THANHTOAN,
  //           rule: RoleAppUser.CAUHINH_THOIGIAN_THANHTOAN,
  //         },
  //         {
  //           name: 'Cấu hình nghiệp vụ đề nghị thanh toán',
  //           key: routesMap.CAUHINH_LOAI_DE_NGHI,
  //           rule: RoleAppUser.CAUHINH_LOAI_DE_NGHI,
  //         },
  //         {
  //           name: 'Cấu hình phê duyệt',
  //           key: routesMap.CAUHINH_LUONG_KY,
  //           rule: RoleAppUser.CAUHINH_LUONG_KY,
  //         },
  //         {
  //           name: 'Khai báo email ngân hàng chi',
  //           key: routesMap.CAUHINH_MAIL_NGANHANG,
  //           rule: RoleAppUser.CAUHINH_MAIL_NGANHANG,
  //         },
  //         {
  //           name: 'Cấu hình thanh toán QR',
  //           key: routesMap.CAUHINH_THANHTOAN_QR,
  //           rule: RoleAppUser.CAUHINH_THANHTOAN_QR,
  //         },
  //         {
  //           name: 'Cấu hình thông tin chi',
  //           key: routesMap.CAUHINH_THONGTIN_CHI,
  //           rule: RoleAppUser.CAUHINH_THONGTIN_CHI,
  //           items: [
  //             {
  //               name: 'Cấu hình ngân hàng chi',
  //               key: routesMap.CAUHINH_THONGTIN_CHI_BANK,
  //               rule: RoleAppUser.CAUHINH_THONGTIN_CHI_BANK,
  //             },
  //             {
  //               name: 'Cấu hình ngân hàng chi',
  //               key: routesMap.CAUHINH_THONGTIN_CHI_BANK2,
  //               rule: RoleAppUser.CAUHINH_THONGTIN_CHI_BANK2,
  //             },
  //             {
  //               name: 'Cấu hình hạn mức chi',
  //               key: routesMap.CAUHINH_THONGTIN_CHI_LIMIT,
  //               rule: RoleAppUser.CAUHINH_THONGTIN_CHI_LIMIT,
  //             },
  //           ],
  //         },
  //         {
  //           name: 'Quản lý thông tin khách hàng',
  //           key: routesMap.CAUHINH_CAN_TRU_V2,
  //           rule: RoleAppUser.CAUHINH_CAN_TRU_V2,
  //         },
  //         {
  //           name: 'Cấu hình trình ký V-Office',
  //           key: routesMap.CAUHINH_TRINHKY_VOFFICE,
  //           rule: RoleAppUser.CAUHINH_TRINHKY_VOFFICE,
  //         },
  //         {
  //           name: 'Cấu hình chức năng rút tiền COD',
  //           key: routesMap.CAUHINH_RUT_TIEN_COD,
  //           rule: RoleAppUser.CAUHINH_RUT_TIEN_COD,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Công tác phí',
  //       key: 'COST_MANAGEMENT_SETTING',
  //       rule: '',
  //       labelGroup: true,
  //       items: [
  //         {
  //           name: 'Thiết lập danh mục',
  //           key: routesMap.COST_MANAGEMENT_SETTING,
  //           rule: RoleAppUser.COST_MANAGEMENT_SETTING,
  //         },
  //         {
  //           name: 'Định mức quy định',
  //           key: routesMap.COST_MANAGEMENT_QUOTA,
  //           rule: RoleAppUser.COST_MANAGEMENT_QUOTA,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: 'Quản lý chi phí',
  //   key: routesMap.COST_MANAGEMENT,
  //   rule: -1,
  //   icon: BsCash,
  //   items: [
  //     {
  //       name: 'Chi phí công tác',
  //       key: routesMap.COST_MANAGEMENT,
  //       rule: RoleAppUser.COST_MANAGEMENT,
  //       items: [
  //         {
  //           name: 'Kế hoạch công tác',
  //           key: routesMap.COST_MANAGEMENT_PLAN,
  //           rule: RoleAppUser.COST_MANAGEMENT_PLAN,
  //         },
  //         {
  //           name: 'Xác nhận kế hoạch công tác',
  //           key: routesMap.COST_MANAGEMENT_CONFIRM_PLAN,
  //           rule: RoleAppUser.COST_MANAGEMENT_CONFIRM_PLAN,
  //         },
  //         {
  //           name: 'Xác nhận hoàn ứng',
  //           key: routesMap.COST_MANAGEMENT_PAYMENT,
  //           rule: RoleAppUser.COST_MANAGEMENT_PAYMENT,
  //         },
  //         {
  //           name: 'Bảng tổng hợp đề nghị chuyển khoản',
  //           key: routesMap.COST_MANAGEMENT_TRANSFER_REQUEST,
  //           rule: RoleAppUser.COST_MANAGEMENT_TRANSFER_REQUEST,
  //         },
  //         {
  //           name: 'Quản lý đặt vé',
  //           key: routesMap.COST_MANAGEMENT_AIR_TICKET,
  //           rule: RoleAppUser.COST_MANAGEMENT_AIR_TICKET,
  //         },
  //         {
  //           name: 'Báo cáo kế hoạch',
  //           key: routesMap.COST_MANAGEMENT_PLAN_REPORT,
  //           rule: RoleAppUser.COST_MANAGEMENT_PLAN_REPORT,
  //         },
  //         {
  //           name: 'Bảng tổng hợp 63 CN',
  //           key: routesMap.COST_MANAGEMENT_PLAN_SYNTHETIC,
  //           rule: RoleAppUser.COST_MANAGEMENT_PLAN_SYNTHETIC,
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    name: 'Quản lý mặt hàng',
    key: routesMap.MERCHANDISE_MANAGEMENT,
    rule: -1,
    icon: FiMic,
    items: [
      {
        name: 'Danh sách mặt hàng',
        key: routesMap.MERCHANDISE_MANAGEMENT_LIST_ITEM,
        rule: RoleAppUser.QUANLY_DS_MATHANG,
      },
      {
        name: 'Danh mục mặt hàng',
        key: routesMap.MERCHANDISE_MANAGEMENT_CATEGORY_ITEM,
        rule: RoleAppUser.QUANLY_DM_MATHANG,
      },  {
        name: 'Thực đơn',
        key: routesMap.MERCHANDISE_MANAGEMENT_MENU,
        rule: RoleAppUser.QUANLY_THUCDON,
      },  {
        name: 'QL nhóm mặt hàng',
        key: routesMap.MERCHANDISE_MANAGEMENT_GROUP_ITEM,
        rule: RoleAppUser.QUANLY_NHOM_MATHANG,
      },  {
        name: 'Quản lý combo',
        key: routesMap.MERCHANDISE_MANAGEMENT_COMBO,
        rule: RoleAppUser.QUANLY_COMBO,
      },
    ],
  },
  {
    name: 'Quản lí QR',
    key: routesMap.QR,
    rule: -1,
    icon: BsCash,
    items: [
      {
        name: 'Cấu hình mã QR',
        key: routesMap.QR_CONFIG,
        rule: -1,
      },
      {
        name: 'Tạo mã QR order',
        key: routesMap.QR_ORDER,
        rule: -1,
      },
    ],
  },
  {
    name: 'Danh mục',
    key: routesMap.CATEGORY,
    rule: -1,
    icon: BsCash,
    items: [
      {
        name: 'Tỉnh',
        key: routesMap.PROVINCE,
        rule: -1,
      },
      {
        name: 'Quận/Huyện',
        key: routesMap.DISTRICT,
        rule: -1,
      },
      {
        name: 'Phường/Xã',
        key: routesMap.WARD,
        rule: -1,
      },
      {
        name: 'Chi tiết mức đá',
        key: routesMap.ICE_LEVEL,
        rule: -1,
      },
      {
        name: 'Chi tiết mức đường',
        key: routesMap.SUGAR_LEVEL,
        rule: -1,
      },
      {
        name: 'Chi tiết mức Size',
        key: routesMap.SIZE_LEVEL,
        rule: -1,
      },
      {
        name: 'Chi tiết mức Topping',
        key: routesMap.TOPPING,
        rule: -1,
      },
      {
        name: 'Chi tiết mức Lượng trà',
        key: routesMap.TEA_LEVEL,
        rule: -1,
      },
      {
        name: 'Nghề nghiệp',
        key: routesMap.JOB,
        rule: -1,
      },
      {
        name: 'Loại hình kinh doanh',
        key: routesMap.BUSSINESS_TYPE,
        rule: -1,
      },
      {
        name: 'Đơn vị tính',
        key: routesMap.UNIT,
        rule: -1,
      },
    ],
  },
];

export function useNavs(listPermission: Auth.IPermission[]) {
  return useMemo(() => {
    // if (ROLE_CONFIG.USE_ROLE) {
    //   return hasNavPermission(navs, listPermission);
    // }

    return navs;
  }, [listPermission]);
}
