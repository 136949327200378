import { smartOrderRequest } from 'api/createRequest';
import { FICO_PATH } from 'api/ficoPath';
import { SagaIterator } from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';
import { UnfoldSagaActionType, unfoldSaga } from 'redux-unfold-saga';
import { ACTION_GET_GROUP_ITEM, ACTION_SAVE_GROUP_ITEM, ACTION_DELETE_GROUP_ITEM } from './actions';

function* getGroupItem(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        const { data } = await smartOrderRequest.get<BaseResponse>(FICO_PATH.icePath);
        if (data?.error) throw new Error(data?.message);

        return data?.data || [];
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* saveGroupItem(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        console.log(action.payload,"payload")
        const { data } = action.payload?.id
          ? await smartOrderRequest.put<BaseResponse>(FICO_PATH.icePathUpdate(action.payload?.id), action.payload)
          : await smartOrderRequest.post<BaseResponse>(FICO_PATH.groupItemPath, action.payload);

        if (Boolean(data?.error)) throw new Error(data?.message);
        return data?.data;
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* deleteGroupItem(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        const { data } = await smartOrderRequest.delete<BaseResponse>(FICO_PATH.icePathUpdate(action.payload?.id));
        if (data?.error) throw new Error(data?.message);

        return data?.data || {};
      },
      key: action.type,
    },
    action.callbacks
  );
}

export default function* GroupItemSaga(): SagaIterator {
  yield takeEvery(ACTION_GET_GROUP_ITEM, getGroupItem);
  yield takeEvery(ACTION_SAVE_GROUP_ITEM, saveGroupItem);
  yield takeEvery(ACTION_DELETE_GROUP_ITEM, deleteGroupItem);
}
