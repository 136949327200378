/* eslint-disable @typescript-eslint/no-explicit-any */
import { withTransaction } from '@elastic/apm-rum-react';
import { groupBy, isEmpty, uniq } from 'lodash';
import React from 'react';
import { IconType } from 'react-icons';

export type AppRouteType = {
  props?: any;
  path: string;
  component: React.ComponentType<any | React.RefAttributes<React.Component<any, any, any>>>;
  rule: string | -1; // -1: mặc định là có, string: ăn theo quyền của người dùng
};

export type NavLinkItem = {
  name: string;
  key: string;
  icon?: IconType;
  items?: NavLinkItem[];
  position?: number;
  rule: string | -1;
  labelGroup?: boolean;
};

export const warpTransactionApm = (name: string, component: React.LazyExoticComponent<React.ComponentType<any>>) => {
  return withTransaction(name, 'route-change')(component);
};

export const delayLazyLoad = <T extends React.ComponentType<any>>(
  componentLazy: Promise<{ default: T }>,
  time = 100
): Promise<{ default: T }> => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(componentLazy), time);
  });
};

export const getRealPath = (path: string): string => {
  const paths = path.split('/').filter((p) => !p.includes(':'));
  return paths.join('/');
};

export const makePermission = (data: Auth.IRoleUser[]) => {
  if (isEmpty(data)) return [];
  const list: Auth.IPermission[] = [];

  const traverseTree = (data: Auth.IRoleUser[]) => {
    data.forEach((node) => {
      if (node.code && node.action) {
        list.push({
          id: node.code,
          name: node.moduleName ?? '',
          position: node.position ?? 0,
          action: node.action.split('-'),
        });
      }
      if (node.childModule?.length) {
        traverseTree(node.childModule);
      }
    });
  };
  traverseTree(data);

  const roleGroup = groupBy(list, 'id');
  const uniqueList: Auth.IPermission[] = [];

  Object.keys(roleGroup).forEach((key) => {
    const roleById = roleGroup[key];

    if (roleById.length > 0) {
      let actions: string[] = [];
      roleById.forEach((item) => {
        actions = actions.concat(item.action);
      });

      uniqueList.push({
        id: key,
        action: uniq(actions),
        name: roleById?.[0].name,
        position: roleById?.[0].position,
      });
    }
  });

  return uniqueList;
};

export const hasRoutePermission = (routes: AppRouteType[], listPermission: Auth.IPermission[]): AppRouteType[] => {
  return routes.filter((route) => {
    if (route.rule === -1) {
      return true;
    }

    return listPermission.find((permission) => route.rule === permission.id);
  });
};

export const hasNavPermission = (navs: NavLinkItem[], listPermission: Auth.IPermission[]): NavLinkItem[] => {
  const navsRes: NavLinkItem[] = [];

  navs.forEach((nav) => {
    if (1 === 1) {
      navsRes.push(nav);
    } else if (nav.items) {
      const navsItemDeep = hasNavPermission(nav.items, listPermission);
      if (navsItemDeep.length > 0) {
        const findNav = listPermission.find((permission) => nav.rule === permission.id);
        navsRes.push({
          ...nav,
          items: navsItemDeep,
          position: findNav?.position,
          name: findNav?.name ?? nav.name,
        });
      }
    } else {
      const findNav = listPermission.find((permission) => nav.rule === permission.id);
      if (findNav) {
        navsRes.push({
          ...nav,
          position: findNav.position,
          name: findNav?.name ?? nav.name,
        });
      }
    }
  });

  navsRes.sort((a, b) => (a.position ?? 0) - (b.position ?? 0));
  return navsRes;
};
