import { toast, ToastContent, ToastOptions } from 'react-toastify';

// import { notification } from 'antd';
// import { ArgsProps } from 'antd/lib/notification';

// export type NotificationProps = Omit<ArgsProps, 'message'>;

export const toastInstance = toast;

const ingoreNoti = (content: ToastContent) => {
  if (typeof content === 'string') return content === 'NOT_NOTI';
  return false;
};

export const toastSuccess = (content: ToastContent, options?: ToastOptions, dismiss = true) => {
  if (!content) return;
  if (ingoreNoti(content)) return;

  options && delete options.type;
  dismiss && toast.dismiss();
  return toast.success(content, options);
};

export const toastInfo = (content: ToastContent, options?: ToastOptions, dismiss = true) => {
  if (!content) return;
  if (ingoreNoti(content)) return;

  options && delete options.type;
  dismiss && toast.dismiss();
  return toast.info(content, options);
};

export const toastWarning = (content: ToastContent, options?: ToastOptions, dismiss = true) => {
  if (!content) return;
  if (ingoreNoti(content)) return;

  options && delete options.type;
  dismiss && toast.dismiss();
  return toast.warning(content, options);
};

export const toastError = (content: ToastContent, options?: ToastOptions, dismiss = true) => {
  if (!content) return;
  if (ingoreNoti(content)) return;

  options && delete options.type;
  dismiss && toast.dismiss();
  return toast.error(content, options);
};

// export const toastSuccess = (mes: React.ReactNode, args?: NotificationProps): void => {
//   return notification.success({
//     message: mes,
//     style: { borderRadius: 6 },
//     ...args,
//   });
// };

// export const toastInfo = (mes: React.ReactNode, args?: NotificationProps): void => {
//   return notification.info({
//     message: mes,
//     style: { borderRadius: 6 },
//     ...args,
//   });
// };

// export const toastError = (mes: React.ReactNode, args?: NotificationProps): void => {
//   return notification.error({
//     message: mes,
//     style: { borderRadius: 6 },
//     ...args,
//   });
// };

// export const toastWarning = (mes: React.ReactNode, args?: NotificationProps): void => {
//   return notification.warning({
//     message: mes,
//     style: { borderRadius: 6 },
//     ...args,
//   });
// };

// export const toastOpen = (mes: React.ReactNode, args?: NotificationProps): void => {
//   return notification.open({
//     message: mes,
//     style: { borderRadius: 6 },
//     ...args,
//   });
// };
