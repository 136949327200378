export const ROLE_CONFIG = {
  USE_ROLE: true,
};

export enum APP_ID {
  SYS_FICO = 0,
}

export const MODULE_ACTION = {
  VIEW: 'VIEW',
  UPDATE: 'UPDATE',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
};

export enum RoleAppUser {
  // cấu hình quyền
  //Danh mục
  CATEGORY_CONFIG = 'CATEGORY_CONFIG',

  TRA_CUU_BUU_CUC = 'TRA_CUU_BUU_CUC',
  TRA_CUU_VAN_DON = 'TRA_CUU_VAN_DON',
  DECLARE_ROLE_CHINHANH = 'DECLARE_ROLE_CHINHANH',

  //Chi công nợ khách hàng
  CHI_CONG_NO_KH = 'CHI_CONG_NO_KH',
  DANG_KY_TAI_KHOAN_CHI_COD = 'CCNKH_DANG_KY',
  PHE_DUYET_TAI_KHOAN_CHI_COD = 'CCNKH_PHE_DUYET',

  //--------------------Khai bai menu mới--------------------
  /**
   * Quản trị hệ thống (ok)
   */
  QUANTRI_HETHONG = 'QUANTRI_HETHONG',
  //Quản lý chức năng
  QUANLY_CHUCNANG = 'QUANLY_CHUCNANG',
  QUANLY_CAUHINH = 'QUANLY_CAUHINH',
  //Quản lý mặt hàng
  QUANLY_MATHANG='QUANLY_MATHANG',
  QUANLY_DS_MATHANG='QUANLY_DS_MATHANG',
  QUANLY_THUCDON='QUANLY_THUCDON',
  QUANLY_DM_MATHANG='QUANLY_DM_MATHANG',
  QUANLY_NHOM_MATHANG='QUANLY_NHOM_MATHANG',
  QUANLY_COMBO='QUANLY_COMBO',

  //Cấu hình/Danh mục
  DANHMUC_CAUHINH = 'DANHMUC_CAUHINH',
  CAUHINH_LUINGAY_QLCP = 'CAUHINH_LUINGAY_QLCP',
  CAUHINH_THOIGIAN_THANHTOAN = 'CAUHINH_THOIGIAN_THANHTOAN',
  CAUHINH_LOAI_DE_NGHI = 'CAUHINH_LOAI_DE_NGHI',
  CAUHINH_LUONG_KY = 'CAUHINH_LUONG_KY',
  CAUHINH_MAIL_NGANHANG = 'CAUHINH_MAIL_NGANHANG',
  CAUHINH_THONGTIN_CHI = 'CAUHINH_THONGTIN_CHI',
  CAUHINH_THONGTIN_CHI_BANK = 'CAUHINH_THONGTIN_CHI_BANK',
  CAUHINH_THONGTIN_CHI_BANK2 = 'CAUHINH_THONGTIN_CHI_BANK2',
  CAUHINH_THONGTIN_CHI_LIMIT = 'CAUHINH_THONGTIN_CHI_LIMIT',
  CAUHINH_THANHTOAN_QR = 'CAUHINH_THANHTOAN_QR',
  CAUHINH_CAN_TRU_V2 = 'CAUHINH_CAN_TRU_V2',
  CAUHINH_THANHTOAN_QR_LIST = 'CAUHINH_THANHTOAN_QR_LIST',
  CAUHINH_THANHTOAN_QR_DETAIL = 'CAUHINH_THANHTOAN_QR_DETAIL',
  CAUHINH_THANHTOAN_QR_STATUS = 'CAUHINH_THANHTOAN_QR_STATUS',
  KHAIBAO_HANMUC_CONGNO = 'KHAIBAO_HANMUC_CONGNO',
  DANHMUC_HANMUC_CONGNO_KPI = 'DANHMUC_HANMUC_CONGNO_KPI',
  DANHMUC_HANMUC_CONGNO_NOPTIEN = 'DANHMUC_HANMUC_CONGNO_NOPTIEN',
  DANHMUC_HANMUC_CONGNO_CAUHINH = 'DANHMUC_HANMUC_CONGNO_CAUHINH',
  CAUHINH_ONOFF_CHUCNANG = 'CAUHINH_ONOFF_CHUCNANG',
  CAUHINH_ONOFF_CHUCNANG_LIST = 'CAUHINH_ONOFF_CHUCNANG_LIST',
  CAUHINH_ONOFF_CHUCNANG_DETAIL = 'CAUHINH_ONOFF_CHUCNANG_DETAIL',
  DANHMUC_KHACHHANG_HETHONG = 'DANHMUC_KHACHHANG_HETHONG',
  CAUHINH_TRINHKY_VOFFICE = 'CAUHINH_TRINHKY_VOFFICE',
  CAUHINH_RUT_TIEN_COD = 'CAUHINH_RUT_TIEN_COD',
  CONFIG_LIMIT_WITHDRAW = 'CONFIG_LIMIT_WITHDRAW',
  CONFIG_FEE_WITHDRAW = 'CONFIG_FEE_WITHDRAW',

  CAUHINH_UPLOAD_MEDIA = 'CAUHINH_UPLOAD_MEDIA',

  /*
    Công nợ phải trả khách hàng (ok)
   */
  CONGNO_TRA_KH = 'CONGNO_TRA_KH',
  //Thanh toán công nợ COD vận đơn
  THANHTOAN_COD_BILL = 'THANHTOAN_COD_BILL',
  THANHTOAN_COD_BANGKE = 'THANHTOAN_COD_BANGKE',
  THANHTOAN_COD_DUYET = 'THANHTOAN_COD_DUYET',
  THANHTOAN_COD_DE_NGHI = 'THANHTOAN_COD_DE_NGHI',

  //Thanh toán công nợ COD tự động
  THANHTOAN_COD_TUDONG = 'THANHTOAN_COD_TUDONG',
  THANHTOAN_COD_TUDONG_BILL = 'THANHTOAN_COD_TUDONG_BILL',
  THANHTOAN_COD_TUDONG_BATCH = 'THANHTOAN_COD_TUDONG_BATCH',
  THANHTOAN_COD_TUDONG_STATEMENT = 'THANHTOAN_COD_TUDONG_STATEMENT',
  THANHTOAN_COD_TUDONG_SUMMARY = 'THANHTOAN_COD_TUDONG_SUMMARY',
  THANHTOAN_COD_TUDONG_XLGD = 'THANHTOAN_COD_TUDONG_XLGD',
  THANHTOAN_COD_TUDONG_HTTD = 'THANHTOAN_COD_TUDONG_HTTD',

  //Thanh toán công nợ COD tự động V2
  THANHTOAN_CONGNO_COD = 'THANHTOAN_CONGNO_COD',
  THANHTOAN_CONGNO_COD_BILL = 'THANHTOAN_CONGNO_COD_BILL',
  THANHTOAN_CONGNO_COD_BATCH = 'THANHTOAN_CONGNO_COD_BATCH',
  THANHTOAN_CONGNO_COD_STATEMENT = 'THANHTOAN_CONGNO_COD_STATEMENT',
  THANHTOAN_CONGNO_COD_SUMMARY = 'THANHTOAN_CONGNO_COD_SUMMARY',
  THANHTOAN_CONGNO_COD_XLGD = 'THANHTOAN_CONGNO_COD_XLGD',
  THANHTOAN_CONGNO_COD_HTTD = 'THANHTOAN_CONGNO_COD_HTTD',

  //Quản lý bill 60 ngày
  BILL_60NGAY = 'BILL_60NGAY',
  BILL_60NGAY_BILL = 'BILL_60NGAY_BILL',
  BILL_60NGAY_BANGKE = 'BILL_60NGAY_BANGKE',
  BILL_60NGAY_CHINHANH = 'BILL_60NGAY_CHINHANH',

  BILL_60NGAY_REPORT = 'BILL_60NGAY_REPORT',
  BILL_60NGAY_BC_TONG_HOP = 'BILL_60NGAY_BC_TONG_HOP',
  BILL_60NGAY_BC_TONG_HOP_QUA_HAN = 'BILL_60NGAY_BC_TONG_HOP_QUA_HAN',
  BILL_60NGAY_BC_QUA_HAN_DETAIL = 'BILL_60NGAY_BC_QUA_HAN_DETAIL',

  //Báo cáo công nợ
  CONGNO_TRA_KH_BAOCAO = 'CONGNO_TRA_KH_BAOCAO',
  CONGNO_BAOCAO_COD_TON = 'CONGNO_BAOCAO_COD_TON',

  //Quản lý rút tiền
  QUAN_LY_RUT_TIEN = 'QUAN_LY_RUT_TIEN',
  QLRT_BANGKE_CHICOD = 'QLRT_BANGKE_CHICOD',
  QLRT_HACHTOAN_TUDONG = 'QLRT_HACHTOAN_TUDONG',

  /*
    Công nợ khách hàng hê thống (ok)
  */
  CONGNO_KH_HT = 'CONGNO_KH_HT',
  //Tổng hợp dữ liệu đối soát
  TONGHOP_DL_DOISOAT = 'TONGHOP_DL_DOISOAT',
  TONGHOP_DL_DOISOAT_CUOC = 'TONGHOP_DL_DOISOAT_CUOC',
  TONGHOP_DL_DOISOAT_COD = 'TONGHOP_DL_DOISOAT_COD',
  //Thanh toán công nợ
  THANHTOAN_CONG_NO = 'THANHTOAN_CONGNO',
  //Báo cáo
  CONGNO_KH_HT_BAOCAO = 'CONGNO_KH_HT_BAOCAO',
  CONGNO_KH_HT_BAOCAO_COD = 'CONGNO_KH_HT_BAOCAO_COD',
  CONGNO_KH_HT_BAOCAO_CUOC = 'CONGNO_KH_HT_BAOCAO_CUOC',
  CONGNO_KH_HT_BAOCAO_GTBS = 'CONGNO_KH_HT_BAOCAO_GTBS',

  /*
   * Công nợ nội bộ (ok)
   */
  CONGNO_NOIBO = 'CONGNO_NOIBO',
  //Công nợ cước
  CONGNO_CUOC = 'CONGNO_CUOC',
  CONGNO_CUOC_BANGKE_BT = 'CONGNO_CUOC_BANGKE_BT',
  //Hạn mức công nợ
  QUANLY_CONGNO = 'QUANLY_CONGNO',
  QUANLY_CONGNO_CN = 'QUANLY_CONGNO_CN',
  QUANLY_CONGNO_DANHSACH = 'QUANLY_CONGNO_DANHSACH',
  QUANLY_CONGNO_DANHSACH_HM = 'QUANLY_CONGNO_DANHSACH_HM',
  QUANLY_CONGNO_DANHSACH_HM_EDIT_TK = 'QUANLY_CONGNO_DANHSACH_HM_EDIT_TK',
  QUANLY_CONGNO_DANHSACH_TH_EDIT_TK = 'QUANLY_CONGNO_DANHSACH_TH_EDIT_TK',
  QUANLY_CONGNO_DANHSACH_NT = 'QUANLY_CONGNO_DANHSACH_NT',
  //
  QUANLY_CONGNO_DXPD = 'QUANLY_CONGNO_DXPD',
  QUANLY_CONGNO_DEXUAT = 'QUANLY_CONGNO_DEXUAT',
  QUANLY_CONGNO_DEXUAT_HM = 'QUANLY_CONGNO_DEXUAT_HM',
  QUANLY_CONGNO_DEXUAT_NT = 'QUANLY_CONGNO_DEXUAT_NT',
  QUANLY_CONGNO_PHEDUYET = 'QUANLY_CONGNO_PHEDUYET',
  QUANLY_CONGNO_PHEDUYET_HM = 'QUANLY_CONGNO_PHEDUYET_HM',
  QUANLY_CONGNO_PHEDUYET_NT = 'QUANLY_CONGNO_PHEDUYET_NT',
  //Báo cáo công nợ
  BAOCAO_CONGNO = 'BAOCAO_CONGNO',
  BAOCAO_CONGNO_TONGHOP = 'BAOCAO_CONGNO_TONGHOP',
  BAOCAO_CONGNO_BILL_QUAHAN = 'BAOCAO_CONGNO_BILL_QUAHAN',
  //Lương công nợ
  LUONG_CONGNO = 'LUONG_CONGNO',
  LUONG_CONGNO_KHAIBAO = 'LUONG_CONGNO_KHAIBAO',
  LUONG_CONGNO_BAOCAO = 'LUONG_CONGNO_BAOCAO',
  //Quản trị công nợ
  CONGNO_QLCONGNO = 'CONGNO_QLCONGNO',
  CNNB_QLCN_MOKHOA = 'CNNB_QLCN_MOKHOA',

  /**
   * Đối soát dữ liệu (OK)
   */
  DOISOAT_DULIEU = 'DOISOAT_DULIEU',
  //Đối soát phải thu
  DOISOAT_PHAITHU = 'DOISOAT_PHAITHU',
  //
  DOISOAT_THANHTOAN_QR = 'DOISOAT_THANHTOAN_QR',
  // DOISOAT_THANHTOAN_QR_CN = 'DOISOAT_THANHTOAN_QR_CN',
  DOISOAT_THANHTOAN_QR_KPI = 'DOISOAT_THANHTOAN_QR_KPI',
  DOISOAT_THANHTOAN_QR_DV = 'DOISOAT_THANHTOAN_QR_DV',
  DOISOAT_THANHTOAN_QR_DOITAC = 'DOISOAT_THANHTOAN_QR_DOITAC',
  DOISOAT_THANHTOAN_QR_NOIBO = 'DOISOAT_THANHTOAN_QR_NOIBO',
  DOISOAT_THANHTOAN_QR_TIENVE = 'DOISOAT_THANHTOAN_QR_TIENVE',
  DOISOAT_THANHTOAN_QR_KPI_CNNB = 'DOISOAT_THANHTOAN_QR_KPI_CNNB',
  DOISOAT_THANHTOAN_QR_RP_HOANTRA = 'DOISOAT_THANHTOAN_QR_RP_HOANTRA',
  DOISOAT_THANHTOAN_QR_KN_HOANTRA = 'DOISOAT_THANHTOAN_QR_KN_HOANTRA',
  // DOISOAT_THANHTOAN_QR_CONFIG_VTMONEY = 'DOISOAT_THANHTOAN_QR_CONFIG_VTMONEY',
  //
  DOISOAT_BAOCAO_THANG = 'DOISOAT_BAOCAO_THANG',

  //Đối soát phải trả
  DOISOAT_PHAITRA = 'DOISOAT_PHAITRA',
  DOISOAT_CHI_COD = 'DOISOAT_CHI_COD',
  DOISOAT_CHI_COD_DOITAC = 'DOISOAT_CHI_COD_DOITAC',
  DOISOAT_CHI_COD_NOIBO = 'DOISOAT_CHI_COD_NOIBO',

  /**
   * Quản lý tài liệu (ok)
   * */
  QUANLY_TAILIEU = 'QUANLY_TAILIEU',
  //Quản lý văn bản
  QUANLY_VANBAN = 'QUANLY_VANBAN',
  QUANLY_VANBAN_BAOCAO = 'QUANLY_VANBAN_BAOCAO',
  QUANLY_VANBAN_CHITIET = 'QUANLY_VANBAN_CHITIET',

  //Hợp đồng
  HOPDONG = 'HOPDONG',
  //Hợp đồng điện tử
  HOPDONG_DIENTU_NOIBO = 'HOPDONG_DIENTU_NOIBO',
  HOPDONG_DIENTU_NOIBO_NHAP = 'HOPDONG_DIENTU_NOIBO_NHAP',
  HOPDONG_DIENTU_NOIBO_NHAP_PL = 'HOPDONG_DIENTU_NOIBO_NHAP_PL',
  HOPDONG_DIENTU_NOIBO_QUANLY = 'HOPDONG_DIENTU_NOIBO_QUANLY',
  //Hợp đồng thuê khoán
  HOPDONG_THUEKHOAN = 'HOPDONG_THUEKHOAN',
  HOPDONG_THUEKHOAN_APPROVED = 'HOPDONG_THUEKHOAN_APPROVED',
  HOPDONG_THUEKHOAN_REQUEST_APPROVE = 'HOPDONG_THUEKHOAN_REQUEST_APPROVE',
  HOPDONG_THUEKHOAN_LIST_CONTRACT = 'HOPDONG_THUEKHOAN_LIST_CONTRACT',
  HOPDONG_THUEKHOAN_REQUEST_RE_SIGN = 'HOPDONG_THUEKHOAN_REQUEST_RE_SIGN',

  //Hợp đồng thuê khoán v2
  V2_HOPDONG_THUEKHOAN = 'V2_HOPDONG_THUEKHOAN',
  V2_HOPDONG_THUEKHOAN_APPROVED = 'V2_HOPDONG_THUEKHOAN_APPROVED',
  V2_HOPDONG_THUEKHOAN_REQUEST_APPROVE = 'V2_HOPDONG_THUEKHOAN_REQUEST_APPROVE',
  V2_HOPDONG_THUEKHOAN_LIST_CONTRACT = 'V2_HOPDONG_THUEKHOAN_LIST_CONTRACT',
  V2_HOPDONG_THUEKHOAN_REQUEST_RE_SIGN = 'V2_HOPDONG_THUEKHOAN_REQUEST_RE_SIGN',

  /**V2_
   *  Sổ phụ/Số dư
   * */
  SOPHU_SODU = 'SOPHU_SODU',
  SOPHU_BAOCAO_SODU = 'SOPHU_BAOCAO_SODU',
  SOPHU_BAOCAO_SAOKE = 'SOPHU_BAOCAO_SAOKE',

  /**
   * Quản lý chi phí (OK)
   */
  QUANLY_CHIPHI = 'QUANLY_CHIPHI',
  //Chi phí thường xuyên
  CHIPHI_THUONGXUYEN = 'CHIPHI_THUONGXUYEN',
  CHIPHI_THUONGXUYEN_BY_PASS_TCT = 'CHIPHI_THUONGXUYEN_BY_PASS_TCT',
  //
  CPTX_TRACUU_HOADON = 'CPTX_TRACUU_HOADON',
  CPTX_KHOANMUC_CHIPHI = 'CPTX_KHOANMUC_CHIPHI',
  //Kê khai chi phí đơn vị
  CPTX_KEKHAI_DONVI = 'CPTX_KEKHAI_DONVI',
  CPTX_KEKHAI_DONVI_HOADON = 'CPTX_KEKHAI_DONVI_HOADON',
  CPTX_KEKHAI_DONVI_BANGKE = 'CPTX_KEKHAI_DONVI_BANGKE',
  CPTX_KEKHAI_DONVI_EXCEL = 'CPTX_KEKHAI_DONVI_EXCEL',
  //CN Kiểm duyệt chi phí
  CPTX_KEKHAI_CN = 'CPTX_KEKHAI_CN',
  CPTX_KEKHAI_CN_KIEMDUYET = 'CPTX_KEKHAI_CN_KIEMDUYET',
  CPTX_KEKHAI_CN_TONGHOP = 'CPTX_KEKHAI_CN_TONGHOP',
  CPTX_KEKHAI_CN_TRINHKY = 'CPTX_KEKHAI_CN_TRINHKY',
  //TCT kiểm duyệt chi phí
  CPTX_KEKHAI_TCT = 'CPTX_KEKHAI_TCT',
  CPTX_KEKHAI_TCT_KIEMDUYET = 'CPTX_KEKHAI_TCT_KIEMDUYET',
  CPTX_KEKHAI_TCT_BANGKE = 'CPTX_KEKHAI_TCT_BANGKE',
  CPTX_KEKHAI_TCT_TONGHOP = 'CPTX_KEKHAI_TCT_TONGHOP',
  //Kê khai chi phí phòng ban
  CPTX_KEKHAI_PB = 'CPTX_KEKHAI_PB',
  CPTX_KEKHAI_PB_KIEMDUYET = 'CPTX_KEKHAI_PB_KIEMDUYET',
  CPTX_KEKHAI_PB_TONGHOP = 'CPTX_KEKHAI_PB_TONGHOP',
  CPTX_KEKHAI_PB_TRINHKY = 'CPTX_KEKHAI_PB_TRINHKY',
  //Quản lý khách hàng
  QUANLY_KHACHHANG = 'QUANLY_KHACHHANG',

  //Thanh toán thù lao
  TTTL = 'TTTL',
  TTTL_LUONG_KHOAN = 'TTTL_LUONG_KHOAN',

  //ver2
  TTTL_TTVH = 'TTTL_TTVH',
  TTTL_LUONG_KHOAN_TTVH = 'TTTL_LUONG_KHOAN_TTVH',
  TTTL_LUONG_KHOAN_TH_DNTT = 'TTTL_LUONG_KHOAN_TH_DNTT',

  TTTL_PTC = 'TTTL_PTC',
  TTTL_LUONG_KHOAN_PTC = 'TTTL_LUONG_KHOAN_PTC',
  TTTL_LUONG_KHOAN_THCK = 'TTTL_LUONG_KHOAN_THCK',
  TTTL_LUONG_KHOAN_HT = 'TTTL_LUONG_KHOAN_HT',

  /*
   * Tra cứu thông tin
   */
  TRA_CUU_THONG_TIN = 'TRA_CUU_THONG_TIN',
  TCTT_THANH_TOAN_KH_CN = 'TCTT_THANH_TOAN_KH_CN',
  TCTT_BKTHU_CPN_KHACHHANG = 'TCTT_BKTHU_CPN_KHACHHANG',
  TCTT_BKTHU_COD_BUUTA = 'TCTT_BKTHU_COD_BUUTA',

  //

  /**
   * Đề nghị thanh toán
   */
  DE_NGHI_THANH_TOAN = 'DE_NGHI_THANH_TOAN',
  DNTT_DENGHI_CHUYENTIEN = 'DNTT_DENGHI_CHUYENTIEN',
  DNTT_TH_DNCT = 'DNTT_TH_DNCT',
  DNTT_PD_DENGHI_CHUYENTIEN = 'DNTT_PD_DENGHI_CHUYENTIEN',
  DNTT_TONGHOP_THANHTOAN = 'DNTT_TONGHOP_THANHTOAN',
  DNTT_TONGHOP_CHUYENKHOAN = 'DNTT_TONGHOP_CHUYENKHOAN',
  DNTT_TONGHOP_HACH_TOAN = 'DNTT_TONGHOP_HACH_TOAN',
  //Quản lý chi phí tự thân
  COST_MANAGEMENT = 'COST_MANAGEMENT',
  COST_MANAGEMENT_PLAN = 'COST_MANAGEMENT_PLAN',
  COST_MANAGEMENT_CONFIRM_PLAN = 'COST_MANAGEMENT_CONFIRM_PLAN',
  COST_MANAGEMENT_AIR_TICKET = 'COST_MANAGEMENT_AIR_TICKET',
  COST_MANAGEMENT_PAYMENT = 'COST_MANAGEMENT_PAYMENT',
  COST_MANAGEMENT_TRANSFER_REQUEST = 'COST_MANAGEMENT_TRANSFER_REQUEST',
  COST_MANAGEMENT_QUOTA = 'COST_MANAGEMENT_QUOTA',

  COST_MANAGEMENT_QUOTA_AIRPORT = 'COST_MANAGEMENT_QUOTA_AIRPORT',
  COST_MANAGEMENT_QUOTA_HOTEL = 'COST_MANAGEMENT_QUOTA_HOTEL',
  COST_MANAGEMENT_QUOTA_LIVING = 'COST_MANAGEMENT_QUOTA_LIVING',
  COST_MANAGEMENT_QUOTA_MOVING = 'COST_MANAGEMENT_QUOTA_MOVING',
  COST_MANAGEMENT_QUOTA_LIVING_COST = 'COST_MANAGEMENT_QUOTA_LIVING_COST',

  COST_MANAGEMENT_SETTING = 'COST_MANAGEMENT_SETTING',
  COST_MANAGEMENT_USER = 'COST_MANAGEMENT_USER',
  COST_MANAGEMENT_DEPARTMENT = 'COST_MANAGEMENT_DEPARTMENT',
  COST_MANAGEMENT_COUNTRY = 'COST_MANAGEMENT_COUNTRY',
  COST_MANAGEMENT_PROVINCE = 'COST_MANAGEMENT_PROVINCE',
  COST_MANAGEMENT_DISTRICT = 'COST_MANAGEMENT_DISTRICT',
  COST_MANAGEMENT_WARD = 'COST_MANAGEMENT_WARD',
  COST_MANAGEMENT_AIRPORT = 'COST_MANAGEMENT_AIRPORT',
  COST_MANAGEMENT_VEHICLE = 'COST_MANAGEMENT_VEHICLE',
  COST_MANAGEMENT_VEHICLE_GROUP = 'COST_MANAGEMENT_VEHICLE_GROUP',
  COST_MANAGEMENT_SERVICE = 'COST_MANAGEMENT_SERVICE',
  COST_MANAGEMENT_SERVICE_GROUP = 'COST_MANAGEMENT_SERVICE_GROUP',
  COST_MANAGEMENT_POSITION = 'COST_MANAGEMENT_POSITION',
  COST_MANAGEMENT_POSITION_GROUP = 'COST_MANAGEMENT_POSITION_GROUP',
  COST_MANAGEMENT_POLICY_LIMIT = 'COST_MANAGEMENT_POLICY_LIMIT',
  COST_MANAGEMENT_PURPOSE = 'COST_MANAGEMENT_PURPOSE',
  COST_MANAGEMENT_DISTANCE_QUOTA = 'COST_MANAGEMENT_DISTANCE_QUOTA',
  COST_MANAGEMENT_GUEST_HOUSE = 'COST_MANAGEMENT_GUEST_HOUSE',
  COST_MANAGEMENT_ROOM = 'COST_MANAGEMENT_ROOM',

  COST_MANAGEMENT_PLAN_REPORT = 'COST_MANAGEMENT_PLAN_REPORT',
  COST_MANAGEMENT_PLAN_SYNTHETIC = 'COST_MANAGEMENT_PLAN_SYNTHETIC',
  COST_MANAGEMENT_PLAN_DELAY_DAY = 'COST_MANAGEMENT_PLAN_DELAY_DAY',
  COST_MANAGEMENT_INVOICE_COST_CONFIG = 'COST_MANAGEMENT_INVOICE_COST_CONFIG',

}
