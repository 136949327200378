import { CaretDownOutlined, LogoutOutlined, UserOutlined, InfoCircleFilled } from '@ant-design/icons';
import { Dropdown, MenuProps, Modal, Typography } from 'antd';
import Text from 'antd/lib/typography/Text';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { memo, useMemo } from 'react';
import { actionAuthLogoutApplication } from 'redux/auth/actions';
import { selectAuthUserInfoSSO } from 'redux/auth/selectors';

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
  key: React.Key,
  label: React.ReactNode,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem => {
  return { key, icon, children, label, type } as MenuItem;
};

const InfoSystem: React.FC = () => {
  const dispatch = useAppDispatch();
  const infoUserSSO = useAppSelector(selectAuthUserInfoSSO);

  const handleLogout = (): void => {
    Modal.confirm({
      title: 'Đăng xuất',
      content: 'Bạn có chắc muốn đăng xuất ?',
      okText: 'Đồng ý',
      onOk: () => {
        setTimeout(() => {
          dispatch(actionAuthLogoutApplication());
        }, 200);
      },
    });
  };

  const items = useMemo(
    () => [
      getItem(
        'dropdown-logout',
        <Typography.Text className="font-medium hover:text-primary" onClick={handleLogout}>
          <LogoutOutlined className="mr-2" />
          Đăng xuất
        </Typography.Text>
      ),
      getItem(
        'dropdown-info',
        <Typography.Text className="font-medium hover:text-primary" onClick={() => alert('comming soon')}>
          <InfoCircleFilled className="mr-2" />
          Thông tin cá nhân
        </Typography.Text>
      ),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <Dropdown menu={{ items: items }} placement="bottomRight">
      <p className="flex m-0 text-sm cursor-pointer">
        <Text className="hidden md:block">{infoUserSSO?.fullName || infoUserSSO?.username}</Text>
        <CaretDownOutlined className="hidden ml-2 md:block" />
        <UserOutlined className="block ml-2 md:hidden" />
      </p>
    </Dropdown>
  );
};

export default memo(InfoSystem);
