import { smartOrderRequest } from 'api/createRequest';
import { FICO_PATH } from 'api/ficoPath';
import Constants from 'config/constants/Constants';
import { SagaIterator } from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';
import { UnfoldSagaActionType, unfoldSaga } from 'redux-unfold-saga';
import { ACTION_GET_SIZE_LEVEL, ACTION_SAVE_SIZE_LEVEL, ACTION_DELETE_SIZE_LEVEL } from './actions';

function* getSizeLevel(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        const { data } = await smartOrderRequest.get<BaseResponse>(FICO_PATH.sizePath);
        if (!Constants.keyValue.ERROR_CODES_SUCCESS.includes(data?.code)) throw new Error(data?.message);

        return data?.data || [];
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* saveSizeLevel(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        const { data } = action.payload?.id
          ? await smartOrderRequest.put<BaseResponse>(FICO_PATH.sizePathUpdate(action.payload?.id), action.payload)
          : await smartOrderRequest.post<BaseResponse>(FICO_PATH.sizePath, action.payload);

        if (!Constants.keyValue.ERROR_CODES_SUCCESS.includes(data?.code)) throw new Error(data?.message);
        return data?.data;
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* deleteSizeLevel(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        const { data } = await smartOrderRequest.delete<BaseResponse>(FICO_PATH.sizePathUpdate(action.payload?.id));
        if (!Constants.keyValue.ERROR_CODES_SUCCESS.includes(data?.code)) throw new Error(data?.message);

        return data?.data || {};
      },
      key: action.type,
    },
    action.callbacks
  );
}

export default function* SizeLevelSaga(): SagaIterator {
  yield takeEvery(ACTION_GET_SIZE_LEVEL, getSizeLevel);
  yield takeEvery(ACTION_SAVE_SIZE_LEVEL, saveSizeLevel);
  yield takeEvery(ACTION_DELETE_SIZE_LEVEL, deleteSizeLevel);
}
