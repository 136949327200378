import { smartOrderRequest } from 'api/createRequest';
import { FICO_PATH } from 'api/ficoPath';
import Constants from 'config/constants/Constants';
import { SagaIterator } from 'redux-saga';
import { takeEvery } from 'redux-saga/effects';
import { UnfoldSagaActionType, unfoldSaga } from 'redux-unfold-saga';
import { ACTION_GET_ICE_LEVEL, ACTION_SAVE_ICE_LEVEL, ACTION_DELETE_ICE_LEVEL } from './actions';

function* getIceLevel(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        const { data } = await smartOrderRequest.get<BaseResponse>(FICO_PATH.icePath);
        if (!Constants.keyValue.ERROR_CODES_SUCCESS.includes(data?.code)) throw new Error(data?.message);

        return data?.data || [];
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* saveIceLevel(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        const { data } = action.payload?.id
          ? await smartOrderRequest.put<BaseResponse>(FICO_PATH.icePathUpdate(action.payload?.id), action.payload)
          : await smartOrderRequest.post<BaseResponse>(FICO_PATH.icePath, action.payload);
        if (!Constants.keyValue.ERROR_CODES_SUCCESS.includes(data?.code)) throw new Error(data?.message);
        return data?.data;
      },
      key: action.type,
    },
    action.callbacks
  );
}

function* deleteIceLevel(action: UnfoldSagaActionType): Iterable<SagaIterator> {
  yield unfoldSaga(
    {
      handler: async () => {
        const { data } = await smartOrderRequest.delete<BaseResponse>(FICO_PATH.icePathUpdate(action.payload?.id));
        if (!Constants.keyValue.ERROR_CODES_SUCCESS.includes(data?.code)) throw new Error(data?.message);

        return data?.data || {};
      },
      key: action.type,
    },
    action.callbacks
  );
}

export default function* IceLevelSaga(): SagaIterator {
  yield takeEvery(ACTION_GET_ICE_LEVEL, getIceLevel);
  yield takeEvery(ACTION_SAVE_ICE_LEVEL, saveIceLevel);
  yield takeEvery(ACTION_DELETE_ICE_LEVEL, deleteIceLevel);
}
