/* eslint-disable prettier/prettier */
export const FICO_BANK_PATH = {
  //Khai báo/Phê duyệt tài khoản COD
  listPartner: '/partner/list-info-partner',
  registerPartner: '/partner/register-info-tm',
  updatePartner: '/partner/update-register',
  updateStatus: '/partner/appr-register',

  // Sổ phụ/Số dư
  listSoDu: '/bank/list-sodu',
  listSoPhu: '/bank/list-sophu',
  exportSoDu: '/bank/export-excel-so-du',
  exportSoPhu: '/bank/export-excel-so-phu',

  //Báo cáo hoàn trả
  listReportRefund: '/baokim/v1/refund/report',
};

export const FICO_PATH = {
  // Auth Login
  getPermissionByUser: '/api/v1/roles/get-permissions', // role quyền
  getPermissionByRole: '/api/v1/roles/get-permissions-by-role', // danh mục
  updatePermissions: '/api/v1/roles/update-permissions', // role quyền
  listPermissions: '/api/v1/roles/list-permissions', // danh mục
  updatePermission: '/api/v1/roles/update-permission', // danh mục
  deletePermission: '/api/v1/roles/delete-permissions', // danh mục
  getRolesByPermission: '/api/v1/roles/get-roles-by-permission', // danh mục
  rolesModule: '/api/v1/roles/module',
  rolesAction: '/api/v1/roles/action',

  //Phân quyền
  roleAll: '/api/v1/roles/module/all',
  postModule: '/api/v1/roles/module',
  listUserAll: '/api/v1/roles/users',

  //Config key
  getConfig: '/config',
  getAllConfig: '/config/list',
  saveConfig: '/config/save',

  // action
  putActionInModule: '/api/v1/roles/action',

  //Organization
  allOrg: '/api/v1/org',
  allPartner: '/api/v1/org/partner',
  postByOrg: '/api/v1/org/post-office',
  userByPost: '/api/v1/org/user',
  orgByUser: '/api/v1/org/get-organization-by-user',
  searchPartner: (partnerCode: string) => `/api/v1/org/partner/${partnerCode}`,

  //DM ice -level
  icePath: '/merchant/stone-items',
  icePathUpdate: (id: number) => `/merchant/stone-items/${id}`,

  //DM sugar -level
  sugarPath: '/merchant/sugar-items',
  sugarPathUpdate: (id: number) => `/merchant/sugar-items/${id}`,

  //DM size -level
  sizePath: '/merchant/size-items',
  sizePathUpdate: (id: number) => `/merchant/size-items/${id}`,

  //DM tea -level
  teaPath: '/merchant/tea-items',
  teaPathUpdate: (id: number) => `/merchant/tea-items/${id}`,

  //DM size -level
  toppingPath: '/merchant/topping-items',
  toppingPathUpdate: (id: number) => `/merchant/topping-items/${id}`,


  //group item
  groupItemPath: '/merchant/itemCategories',

  //menu
   menuPath: '/merchant/menu-items',
  menuPathGet: '/merchant/menu-items',
    menuPathUpdate: (id: number) => `/merchant/menu-items/${id}`,
};
