import { Image, Layout, Menu, MenuProps } from 'antd';
import { useAppSelector } from 'app/hooks';
import logoAppDev from 'assets/images/logo-so.png';
import logoAppVTP from 'assets/images/logo-smart-order.png';
import { NavLinkItem } from 'layouts/helper';
import React, { useMemo, useState } from 'react';
import { AiOutlineMenuUnfold } from 'react-icons/ai';
import { FiArrowLeft } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { selectAuthListPermission } from 'redux/auth/selectors';
import { REACT_APP_PRODUCT } from 'utils/env';
import { useNavs } from '../useNavs';

const { Sider } = Layout;

type MenuItem = Required<MenuProps>['items'][number];

const getItem = (
  key: React.Key,
  label: React.ReactNode,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem => {
  return { key, icon, children, label, type } as MenuItem;
};

const LayoutSidebar: React.FC = () => {
  // const location = useLocation();
  const listPermission = useAppSelector(selectAuthListPermission);

  const navs = useNavs(listPermission);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  // const [selectedKeys, setSelectedKeys] = useState<string[] | undefined>();
  // const [openKeys, setOpenKeys] = useState<string[] | undefined>();

  // useEffect(() => {
  //   const pathNames = location.pathname.split('/').filter((x) => x);

  //   if (pathNames.length) {
  //     const pathName = `/${pathNames.join('/')}`;
  //     const findRoute = routes.find((route) => {
  //       return route.path !== routesMap.HOME && pathName.indexOf(getRealPath(route.path)) !== -1;
  //     });

  //     if (findRoute) {
  //       setOpenKeys(findRoute.openKey);
  //       setSelectedKeys(findRoute.activeKey);
  //     }
  //   }

  //   if (location.pathname === routesMap.HOME) {
  //     setSelectedKeys([routesMap.HOME]);
  //   }
  // }, [location.pathname]);

  const fnHandleNav = (navs: NavLinkItem[]) => {
    const items_v2: MenuItem[] = [];

    navs.forEach((nav) => {
      const Icon = nav.icon ? <nav.icon size={15} /> : undefined;

      if (nav.items?.length) {
        const children = fnHandleNav(nav.items);
        items_v2.push(getItem(nav.key, nav.name, Icon, children, nav.labelGroup ? 'group' : undefined));
      } else {
        items_v2.push(getItem(nav.key, <Link to={nav.key}>{nav.name}</Link>, Icon));
      }
    });

    return items_v2;
  };

  const items = useMemo((): MenuItem[] => {
    return fnHandleNav(navs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navs]);

  const handleToggle = (): void => {
    setCollapsed(!collapsed);
  };

  const logoApp = useMemo(() => {
    return REACT_APP_PRODUCT ? logoAppVTP : logoAppDev;
  }, []);

  const renderLogo = (): JSX.Element => {
    return collapsed ? (
      <div className="cursor-pointer sidebar-logo justify-evenly">
        <AiOutlineMenuUnfold size={25} className="text-primary" onClick={handleToggle} />
      </div>
    ) : (
      <div className="p-3 sidebar-logo justify-evenly">
        <FiArrowLeft size={25} className="text-gray-500 cursor-pointer" onClick={handleToggle} />
        <Image preview={false} alt="VTP-logo" style={{ maxWidth: 150 }} src={logoApp} />
      </div>
    );
  };

  const renderMenu = (): JSX.Element => {
    return (
      <Menu
        theme="light"
        mode="inline"
        items={items}
        triggerSubMenuAction="click"
        // openKeys={openKeys}
        // selectedKeys={selectedKeys}
        className="mt-1 font-medium select-none"
        // onSelect={(info): void => setSelectedKeys(info.selectedKeys)}
        // onOpenChange={(keys): void => setOpenKeys(keys)}
      />
    );
  };

  return (
    <Sider
      width={250}
      collapsible
      theme="light"
      trigger={null}
      breakpoint={'xl'}
      collapsedWidth={55}
      collapsed={collapsed}
      className="menu-sidebar"
      onBreakpoint={setCollapsed}
    >
      {renderLogo()}
      {renderMenu()}
    </Sider>
  );
};

export default LayoutSidebar;
