/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable prettier/prettier */
import { RcFile } from 'antd/lib/upload';
import { Dictionary, cloneDeep, get, size, toNumber, toString, trim, trimEnd } from 'lodash';
import moment, { Moment } from 'moment';
import numeral from 'numeral';
import * as XLSX from 'xlsx';
import xml2js from 'xml2js';
import { MESSAGE_ERR, regexPhone, regexSpaceTab } from './constants';
import { readMoney } from './readMoney';

export { readMoney };

export const getMessageError = <T = any>(error: T, path = 'message'): string => {
  let message = get(error, path) || MESSAGE_ERR;

  if (message === 'null') {
    message = MESSAGE_ERR;
  }
  if (message?.includes('INVALID_OTP')) {
    message = 'Mã xác thực không đúng. Vui lòng kiểm tra lại';
  }
  if (message === 'Network Error') {
    message = 'Mất kết nối đến máy chủ. Vui lòng thử lại sau ít phút';
  }
  if ('Token hết hạn. Cần đăng nhập lại!'.includes(message)) {
    message = 'NOT_NOTI';
  }

  return message;
};

export const getIndexTable = (current: number, pageSize: number, index: number) => {
  return (current - 1) * pageSize + index + 1;
};

export const delay = (time = 500) => new Promise<void>((resolve) => setTimeout(resolve, time));

export const capitalizeString = (str: string): string => {
  if (!str) return '';
  str = str.trim();
  return `${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`;
};

export const formatterNumber = (num?: string | number): string => {
  if (!num) return '';
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const numberFormat = (num?: number | string | null, subfix = '0'): string => {
  if (num === undefined || num === null) return subfix;

  return numeral(num).format('0,0');
};

export function numberFormatDecimal(value: string | number | undefined): string {
  if (!value) return '0';
  if (typeof value === 'number') {
    value = value.toString().trim();
  }

  if (value.includes('.')) {
    let newNumber = numeral(toNumber(value)).format('0.0,0');
    while (newNumber[newNumber.length - 1] === '0') {
      newNumber = trimEnd(newNumber, '0');
    }
    return newNumber;
  } else {
    return numeral(value).format('0,0');
  }
}

export function getValueNumberFormat(value: string | undefined, subfix = '0'): string {
  if (!value) return subfix;
  value = value.trim();

  if (Number.isNaN(Number.parseFloat(value))) return value;
  return (numeral(value).value() || subfix).toString();
}

export const numberFixed = (num?: number | null, fractionDigits = 2) => {
  if (!num) return 0;
  if (typeof num !== 'number') throw new Error('input must number');

  return Number.parseFloat(num.toFixed(fractionDigits));
};

const intlNF = new Intl.NumberFormat(); //default: English
const vndIntlNF = new Intl.NumberFormat('vi-VN'); //vi-VN: Vietnamese
export const formatNumber = (num?: number | string | null, type: 'vnd' | 'en' = 'en', subfix = '0'): string => {
  if (num === undefined || num === null) return subfix;

  let number = 0;
  if (typeof num === 'number') {
    number = num;
  } else if (typeof num === 'string') {
    number = Number.parseFloat(num);
  } else {
    return subfix;
  }

  if (Number.isNaN(number)) {
    return subfix;
  }

  if (type === 'vnd') {
    return vndIntlNF.format(number);
  }
  return intlNF.format(number);
};

export const checkNumber = (num?: number | null) => {
  return num !== undefined && num !== null;
};

export const dateTimeFormat = (time: any, formatOut: string, formatIn?: string): string => {
  if (!time) return '';
  if (typeof time === 'number' && time < 0) return '';

  try {
    const dateTime = moment(time, formatIn).format(formatOut);
    return dateTime !== 'Invalid date' ? dateTime : '';
  } catch {
    return '';
  }
};

export const normalizeString = <T = any>(value: T): string => {
  if (!value) return '';
  if (typeof value !== 'string') return '';

  return value.replace(/\s\s/g, ' ');
};

export const makeJoinString = (arrs: (string | null | undefined)[], separator = ',') => {
  return arrs.filter((x) => !!x).join(separator);
};

export const calculatorSizeFile = (files: File[]): number => {
  return files.reduce((total, file) => {
    return total + file.size;
  }, 0);
};

export const checkDuplicateFile = (lstFile: RcFile[], fileChecked: RcFile): boolean => {
  if (size(lstFile) <= 0) return false;

  for (let idx = 0; idx < lstFile.length; idx++) {
    if (
      lstFile[idx].name === fileChecked.name &&
      lstFile[idx].size === fileChecked.size &&
      lstFile[idx].type === fileChecked.type
    ) {
      return true;
    }
  }

  return false;
};

interface IFileUpload {
  size?: number;
  type?: string;
  name?: string;
}

/**
 *
 * @param filesUploaded - file uploaded
 * @param filesBeforeUPload - file before upload
 * @returns boolean
 */
export const checkDuplicateFileAll = (
  filesUploaded: IFileUpload[],
  filesBeforeUPload: RcFile[],
  fileChecked: RcFile
): boolean => {
  if (size(filesUploaded) + size(filesBeforeUPload) <= 0) return false;

  const mapFileBeforeUpload = filesUploaded.map((file) => {
    return {
      name: file.name ?? '',
      size: file.size ?? 0,
      type: file.type ?? '',
    };
  });

  const allFile = mapFileBeforeUpload.concat(filesBeforeUPload);

  const lengthFile = allFile.length;

  for (let idx = 0; idx < lengthFile; idx++) {
    if (
      allFile[idx].name === fileChecked.name &&
      allFile[idx].size === fileChecked.size &&
      allFile[idx].type === fileChecked.type
    ) {
      return true;
    }
  }

  return false;
};
export const checkRightFileType = (fileChecked: RcFile, listTypeFile?: string[]): boolean => {
  const fileType = fileChecked.name?.split('.')?.pop()?.toLowerCase() || '';

  const fileTypeAccepted = ['mp3', 'mp4', 'jpg', 'jpeg', 'png', 'doc', 'docx', 'xls', 'xlsx', 'avi', 'pdf'];
  return listTypeFile ? !listTypeFile.includes(fileType) : !fileTypeAccepted.includes(fileType);
};

export const removeWhitespace = (raw?: string) => {
  if (!raw) return '';
  if (typeof raw !== 'string') return '';
  return raw.replace(/\t|\v|\f|\r|\n/, '');
};

export const removeAccents = (str: string): string => {
  const accentsMap = [
    'aàảãáạăằẳẵắặâầẩẫấậ',
    'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
    'dđ',
    'DĐ',
    'eèẻẽéẹêềểễếệ',
    'EÈẺẼÉẸÊỀỂỄẾỆ',
    'iìỉĩíị',
    'IÌỈĨÍỊ',
    'oòỏõóọôồổỗốộơờởỡớợ',
    'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
    'uùủũúụưừửữứự',
    'UÙỦŨÚỤƯỪỬỮỨỰ',
    'yỳỷỹýỵ',
    'YỲỶỸÝỴ',
  ];

  for (let i = 0; i < accentsMap.length; i++) {
    const re = new RegExp('[' + accentsMap[i].substring(1) + ']', 'g');
    const char = accentsMap[i][0];
    str = str.replace(re, char);
  }

  return str;
};

export const serializableString = (input: string | undefined) => {
  if (!input) return '';
  if (typeof input !== 'string') return '';

  return removeAccents(input).toLowerCase();
};

export const filterOptionSelect = (text: string, option: any) => {
  const textSearch = toString(get(option, 'children')).trim().toLowerCase();
  return removeAccents(textSearch).includes(removeAccents(text.trim().toLowerCase()));
};

export const readXLSX = (file: File | undefined, opts?: XLSX.ParsingOptions) => {
  return new Promise<XLSX.WorkBook>((resolve, reject) => {
    if (!file) {
      reject(new Error('File is undefined'));
      return;
    }

    const fileType = file.name?.split('.')?.pop()?.toLowerCase() || '';
    const fileTypeAccepted = ['xls', 'xlsx'];
    if (!fileTypeAccepted.includes(fileType)) {
      reject(new Error('File is not support'));
      return;
    }

    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = (event: ProgressEvent<FileReader>) => {
        const dataResult = event.target?.result;
        const wb = XLSX.read(dataResult, { type: 'buffer', ...opts });
        resolve(wb);
      };
    } catch (error) {
      reject(error);
    }
  });
};

export const readXML = <T = any>(file: File | undefined) => {
  return new Promise<T>((resolve, reject) => {
    if (!file) {
      reject(new Error('File is undefined'));
      return;
    }

    try {
      const reader = new FileReader();
      reader.onload = async (event: ProgressEvent<FileReader>) => {
        const dataResult = event.target?.result;

        if (dataResult && typeof dataResult === 'string') {
          // const dataParser = new DOMParser().parseFromString(dataResult, 'text/xml');
          const parser = new xml2js.Parser();
          const objRes = await parser.parseStringPromise(dataResult);

          resolve(objRes);
        } else {
          reject(new Error('Data of file not support'));
        }
      };
      reader.readAsText(file);
    } catch (error) {
      reject(error);
    }
  });
};

export const makePhone = (phone: string | null | undefined) => {
  if (!phone) return '';
  phone = phone.trim();

  if (phone.startsWith('84')) phone = '0' + phone.slice(2);
  return phone;
};

export const convertPhone = (phone: string | null | undefined) => {
  if (!phone) return '';
  phone = phone.trim();

  if (!regexPhone.test(phone)) return phone;
  if (!phone.startsWith('84')) phone = '84' + phone.slice(1);

  return phone;
};

export const wrapChangeInput =
  (setValueFunction: Function, normalize?: (value: string) => string | undefined) =>
  (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const value = event.currentTarget.value;
    if (normalize) {
      setValueFunction(normalize(value));
    } else {
      setValueFunction(value);
    }
  };

export const onChangeTextInputValueNumberANTD =
  (setValueFunction: Function) =>
  (value: number | null): void => {
    setValueFunction(value?.toString() || '');
  };

export const removeSpaceTab = (text?: string) => {
  if (text === undefined) return '';
  return text.replace(regexSpaceTab, '');
};

export const onChangeOptionSelect =
  (setValueFunction: Function) =>
  (value?: string | number): void => {
    setValueFunction(value);
  };

export const onChangeTextInputValue =
  (setValueFunction: Function) =>
  (event: React.FormEvent<HTMLInputElement> | Event): void => {
    const value = get(event, 'currentTarget.value', '');
    setValueFunction(value || '');
  };

export const onChangeTextTrimInputValue =
  (setValueFunction: Function) =>
  (event: React.FormEvent<HTMLInputElement> | Event): void => {
    const value = get(event, 'currentTarget.value', '');
    setValueFunction(trim(value || ''));
  };

export const disabledDateStart = (current: moment.Moment, date: Moment | undefined | null, days?: number): boolean => {
  const timeMax = cloneDeep(date) ?? moment();
  const timeBack = cloneDeep(timeMax).subtract(days, 'days');
  if (timeMax && current >= timeBack && current <= timeMax) {
    return false;
  }
  return true;
};

// chọn được trong khoảng ngày kết thúc lùi 31 ngày => đến ngày kết thúc
export const disabledDateEnd = (current: moment.Moment, date: Moment | undefined | null, days?: number): boolean => {
  const timeMin = cloneDeep(date) ?? moment();
  const timePrev = cloneDeep(timeMin).add(days, 'days');
  if (timeMin && current < timePrev && current >= timeMin) {
    return false;
  }
  return true;
};

export const checkNameFileinValid = (file: RcFile) => {
  const regex = /\+/;
  const fileType = file.name || '';
  return regex.test(fileType);
};

export const makeMapArrObj = <T extends Record<string, any>>(of: T[], keyValue: keyof T, keyLabel: keyof T) => {
  return of.reduce((obj: Dictionary<string>, i) => {
    obj[i[keyValue]] = i[keyLabel];
    return obj;
  }, {});
};
