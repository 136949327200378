import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Layout, Select } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { RoleAppUser } from 'common/phanQuyen';
import InfoSystem from 'components/InfoSystem';
// import useRoleUserByPO from 'hooks/useRoleUserByPO';
import routesMap from 'layouts/routesMap';
import { find, isEmpty, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  actionAuthChangePostUser,
  actionAuthGetInfoUserSSO,
  actionAuthSetAccessToken,
  actionAuthSetSelectedPO,
} from 'redux/auth/actions';
import { selectAuthListPostOffice, selectAuthSelectedPO } from 'redux/auth/selectors';
// import { actionCommonGlobalLoad, actionCommonSearchOrder } from 'redux/Common/actions';
import { filterOptionSelect, getMessageError, wrapChangeInput } from 'utils/common';
import { getDataStorage, setDataStorage, STORAGE_KEY } from 'utils/storage';
import { toastError } from 'utils/toastify';

const { Header } = Layout;

const LayoutHeader: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const [maVanDon, setMaVanDon] = useState('');
  const selectedPO = useAppSelector(selectAuthSelectedPO);
  const listPostOffice = useAppSelector(selectAuthListPostOffice);

  // const [role] = useRoleUserByPO(RoleAppUser.TRA_CUU_VAN_DON);

  useEffect(() => {
    if (listPostOffice && listPostOffice.length > 0) {
      const userInfo = getDataStorage<Auth.UserInfo>(STORAGE_KEY.USER_INFO);
      // const postOffice = find(listPostOffice, (item) => item.ma_buucuc === userInfo.ma_buucuc);

      // if (isEmpty(userInfo?.ma_buucuc)) {
      //   setDataStorage(STORAGE_KEY.POST_OFFICE, listPostOffice[0]);
      //   dispatch(actionAuthSetSelectedPO(listPostOffice[0]));
      // } else {
      //   setDataStorage(STORAGE_KEY.POST_OFFICE, postOffice);
      //   dispatch(actionAuthSetSelectedPO(postOffice));
      // }
    }
  }, [dispatch, listPostOffice]);

  const handleSelectedPO = (value: string): void => {
    const postOffice = find(listPostOffice, (item) => item.ma_buucuc === value);

    const payload = { postoffice: postOffice?.ma_buucuc };
    // dispatch(actionCommonGlobalLoad(true));
    dispatch(
      actionAuthChangePostUser(payload, {
        onSuccess: (data?: { info?: object; token?: string }) => {
          // dispatch(actionCommonGlobalLoad(false));

          setDataStorage(STORAGE_KEY.POST_OFFICE, postOffice);
          dispatch(actionAuthSetSelectedPO(postOffice));

          setDataStorage(STORAGE_KEY.USER_INFO, data?.info);
          setDataStorage(STORAGE_KEY.ACCESS_TOKEN, data?.token);

          dispatch(actionAuthGetInfoUserSSO(data?.info));
          dispatch(actionAuthSetAccessToken(data?.token));
        },
        onFailure: (error: Error) => {
          toastError(getMessageError(error));
          // dispatch(actionCommonGlobalLoad(false));
        },
      })
    );
  };

  const handleSubmitSearch = () => {
    const value = maVanDon.trim().toUpperCase();
    // dispatch(actionCommonSearchOrder({ orderCode: value }));
    //
    if (value.length > 0 && location.pathname !== routesMap.HOME) {
      navigate(routesMap.HOME);
    }
  };

  return (
    <Header className="header-bar bg-white px-[30px]">
      <div className="flex h-[100%] w-[100%] items-center justify-end">
        {/* {role.isView ? (
          <div className="header-bar-search w-[120px] md:w-[300px] flex items-center">
            <Input
              maxLength={200}
              value={maVanDon}
              placeholder="Tra cứu vận đơn"
              onPressEnter={handleSubmitSearch}
              onChange={wrapChangeInput(setMaVanDon)}
            />
            <Button onClick={handleSubmitSearch} className="header-bar-search-btn" icon={<SearchOutlined />} />
          </div>
        ) : (
          <div />
        )} */}

        <div className="flex items-center">
          {/* <Select
            showSearch
            placeholder="Chọn bưu cục"
            optionFilterProp="children"
            onSelect={handleSelectedPO}
            value={selectedPO?.ma_buucuc}
            className="w-[110px] md:w-[300px]"
            filterOption={filterOptionSelect}
          >
            {map(listPostOffice, (item, idx) => (
              <Select.Option value={item.ma_buucuc || ''} key={idx}>
                {item.ten_buucuc || ''} - {item.ma_buucuc || ''}
              </Select.Option>
            ))}
          </Select> */}
          {/* <div className="mx-2 h-[38px] w-[1px] bg-[#e1e1e1]" /> */}
          <InfoSystem />
        </div>
      </div>
    </Header>
  );
};

export default LayoutHeader;
