import { Spin } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { APP_ID } from 'common/phanQuyen';
import LoadingSkeleton from 'components/LoadingSkeleton';
import PrivateLayout, { useRoutes as usePrivateRoutes } from 'layouts/PrivateLayout';
import PublicLayout, { useRoutes as usePublicRoutes } from 'layouts/PublicLayout';
import { AppRouteType, delayLazyLoad, makePermission } from 'layouts/helper';
import { isEmpty } from 'lodash';
import React, { lazy, useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
// import { actionCommonGlobalLoad, action_COMMON_SYS_ALL_ORG, action_COMMON_SYS_ALL_POST } from 'redux/Common/actions';
// import { selectGlobalLoad } from 'redux/Common/selector';
import { actionAuthLogoutApplication } from 'redux/auth/actions';
import { store } from 'app/store';
import routesMaps from 'layouts/routesMap';

import {
  actionAuthGetInfoUserSSO,
  actionAuthGetPostByUser,
  actionAuthGetRoleByUser,
  actionAuthSetAccessToken,
  actionAuthSetListPermissionV2,
} from 'redux/auth/actions';
import { selectAuthAccessToken, selectAuthListPermission, selectAuthUserInfoSSO } from 'redux/auth/selectors';
import { getMessageError } from 'utils/common';
import { STORAGE_KEY, getDataStorage } from 'utils/storage';
import { toastError } from 'utils/toastify';

const Page404 = lazy(() => delayLazyLoad(import('components/Page404')));

const makeRoutes = (routes: AppRouteType[]): JSX.Element[] => {
  return routes.map((route, idx) => {
    const { component: Component, props } = route;
    return <Route key={idx} path={route.path} element={<Component {...props} />} />;
  });
};

const AppContainer: React.FC = () => {
  const dispatch = useAppDispatch();

  // const loading = useAppSelector(selectGlobalLoad);
  const accessToken = useAppSelector(selectAuthAccessToken);
  const userInfoSSO = useAppSelector(selectAuthUserInfoSSO);
  const listPermission = useAppSelector(selectAuthListPermission);

  const routePublic = usePublicRoutes();
  const routePrivate = usePrivateRoutes(listPermission);

  useEffect(() => {
    const userInfo = getDataStorage(STORAGE_KEY.USER_INFO);
    const accessToken = getDataStorage(STORAGE_KEY.ACCESS_TOKEN);
    dispatch(actionAuthGetInfoUserSSO(userInfo));
    dispatch(actionAuthSetAccessToken(accessToken));
  }, [dispatch]);

  useEffect(() => {
    if (accessToken && userInfoSSO?.username) {
      // dispatch(actionCommonGlobalLoad(true));
      const payloadUser = { appIp: APP_ID.SYS_FICO, username: userInfoSSO.username };

      // dispatch(
      //   actionAuthGetRoleByUser(payloadUser, {
      //     onSuccess: (data: Auth.IRoleUser[]) => {
      //       const permission = makePermission(data);
      //       const payload = { username: userInfoSSO?.username };

      //       dispatch(actionAuthGetPostByUser(payload));
      //       dispatch(actionAuthSetListPermissionV2(permission));

      //       if (isEmpty(data)) {
      //         toastError(
      //           'Tài khoản không có quyền truy cập. Vui lòng liên hệ quản trị viên để được cấp quyền truy cập'
      //         );
      //       }
      //     },
      //     onFailure: (error: Error) => {
      //       // dispatch(actionCommonGlobalLoad(false));
      //       toastError(getMessageError(error));
      //     },
      //   })
      // );
    }
    // else {
    //   console.log('run else');
    //   window.location.href = `${routesMaps.LOGIN}`;
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userInfoSSO]);

  return (
    <Spin spinning={false} tip="Đang tải dữ liệu. Vui lòng đợi...">
      <React.Suspense fallback={<LoadingSkeleton />}>
        <Routes>
          <Route path="/" element={<Outlet />}>
            {/* Public routes */}
            <Route element={<PublicLayout />}>{makeRoutes(routePublic)}</Route>

            {/* Private route */}
            <Route element={<PrivateLayout />}>{makeRoutes(routePrivate)}</Route>

            {/* Catch all */}
            <Route path="*" element={<Page404 />} />
          </Route>
        </Routes>
      </React.Suspense>
    </Spin>
  );
};

export default AppContainer;
