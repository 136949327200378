import { store } from 'app/store';
import { InternalAxiosRequestConfig } from 'axios';
import CryptoJS from 'crypto-js';
import { isEmpty, merge } from 'lodash';
import { REACT_APP_SMART_ORDER_SECRET_KEY } from './env';

const ENCRYPT_DATA = 'encryptData';
const ENCRYPT_HEADER = 'Encrypt-Data';

export const jsEncryptAES = (plainData: string) => {
  if (!plainData) return '';
  if (!REACT_APP_SMART_ORDER_SECRET_KEY) return plainData;

  const secet_key = REACT_APP_SMART_ORDER_SECRET_KEY;
  const encrypted = CryptoJS.AES.encrypt(plainData, secet_key, {
    iv: CryptoJS.enc.Hex.parse(secet_key),
  });

  return encrypted.toString();
};

export const jsDecryptAES = (plainData: string) => {
  if (!plainData) return '';
  if (!REACT_APP_SMART_ORDER_SECRET_KEY) return plainData;

  const secet_key = REACT_APP_SMART_ORDER_SECRET_KEY;
  const encrypted = CryptoJS.AES.decrypt(plainData, secet_key, {
    iv: CryptoJS.enc.Hex.parse(secet_key),
  });

  return encrypted.toString(CryptoJS.enc.Utf8);
};

export const injectEncrypt = (config: InternalAxiosRequestConfig) => {
  if (!config.params && !config.data) return config;

  const defaultData = {
    userid: store.getState().auth.userInfoSSO?.userid,
  };

  let plainData = {};
  if (config.params && config.params[ENCRYPT_DATA]) {
    plainData = config.params[ENCRYPT_DATA];
    delete config.params[ENCRYPT_DATA];
  }

  if (config.data && config.data[ENCRYPT_DATA]) {
    plainData = config.data[ENCRYPT_DATA];
    delete config.data[ENCRYPT_DATA];
  }

  if (isEmpty(plainData)) return config;

  const encrypted = jsEncryptAES(JSON.stringify(merge(defaultData, plainData)));
  config.headers.set(ENCRYPT_HEADER, encrypted);

  return config;
};
