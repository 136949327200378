import { Layout } from 'antd';
import LoadingSkeleton from 'components/LoadingSkeleton';
// import TraCuuBuuCuc from 'features/TrangChu/TraCuuBuuCuc';
import routesMaps from 'layouts/routesMap';
import { trim } from 'lodash';
import React, { useMemo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { Waiter } from 'react-wait';
import { STORAGE_KEY, getDataStorage } from 'utils/storage';
import AppReady from './AppReady';
import LayoutHeader from './LayoutHeader';
import LayoutSidebar from './LayoutSidebar';

const { Content } = Layout;

const PrivateLayout: React.FC = () => {
  const isLogged = useMemo(() => {
    return Boolean(trim(getDataStorage(STORAGE_KEY.ACCESS_TOKEN)));
  }, []);

  return isLogged ? (
    <Layout className="min-h-screen">
      <LayoutSidebar />
      <Layout className="bg-[#e9effb]">
        <LayoutHeader />
        <Content className="px-6 py-3">
          <Waiter>
            <React.Suspense fallback={<LoadingSkeleton />}>
              <Outlet />
              {/* <TraCuuBuuCuc /> */}
            </React.Suspense>
          </Waiter>

          {/* <BackTop visible visibilityHeight={1300}>
            <Button type="primary" style={{ borderRadius: '50%', width: 50, height: 50 }}>
              <UpOutlined className="text-lg" />
            </Button>
          </BackTop> */}
        </Content>
      </Layout>
      <AppReady />
    </Layout>
  ) : (
    <Navigate to={routesMaps.LOGIN} />
  );
};

export default PrivateLayout;
export { useRoutes } from './useRoutes';
