const HOME = '/';
const LOGIN = '/login';
const REGISTER = '/register';

const NOT_FOUND = '/404';

// cấu hình danh mục
const CATEGORY = '/category';
const PROVINCE = `${CATEGORY}/province`;
const DISTRICT = `${CATEGORY}/district`;
const WARD = `${CATEGORY}/ward`;
const ICE_LEVEL = `${CATEGORY}/ice-level`;
const SUGAR_LEVEL = `${CATEGORY}/sugar-level`;
const SIZE_LEVEL = `${CATEGORY}/size-level`;
const TOPPING = `${CATEGORY}/topping`;
const TEA_LEVEL = `${CATEGORY}/tea-level`;
const JOB = `${CATEGORY}/job`;
const BUSSINESS_TYPE = `${CATEGORY}/business-type`;
const UNIT = `${CATEGORY}/unit`;

// cấu hình QR
const QR = '/QR';
const QR_CONFIG = `${QR}-config`;
const QR_ORDER = `${QR}-order`;
//Quản trị hệ thống
const QUANTRI_HETHONG = '/quan-tri-he-thong';
const QUANLY_CHUCNANG = `${QUANTRI_HETHONG}/quan-ly-chuc-nang`;
const QUANLY_CAUHINH = `${QUANTRI_HETHONG}/quan-ly-cau-hinh`;
const DANHMUC_CAUHINH = `${QUANTRI_HETHONG}/danh-muc-cau-hinh`;
const CAUHINH_LUINGAY_QLCP = `${QUANTRI_HETHONG}/cau-hinh-lui-ngay-qlcp`;
const CAUHINH_THONGTIN_CHI = `${QUANTRI_HETHONG}/cau-hinh-thong-tin-chi`;
const CAUHINH_THONGTIN_CHI_BANK = `${QUANTRI_HETHONG}/cau-hinh-ngan-hang-chi`;
const CAUHINH_THONGTIN_CHI_BANK2 = `${QUANTRI_HETHONG}/cau-hinh-ngan-hang-chi-new`;
const CAUHINH_THONGTIN_CHI_LIMIT = `${QUANTRI_HETHONG}/cau-hinh-ham-muc-chi`;
const CAUHINH_MAIL_NGANHANG = `${QUANTRI_HETHONG}/cau-hinh-mail-ngan-hang`;
const CAUHINH_THANHTOAN_QR = `${QUANTRI_HETHONG}/cau-hinh-thanh-toan-qr`;
const CAUHINH_CAN_TRU_V2 = `${QUANTRI_HETHONG}/cau-hinh-can-tru-kh`;
const KHAIBAO_HANMUC_CONGNO = `${QUANTRI_HETHONG}/khai-bao-han-muc-cong-no`;
const CAUHINH_ONOFF_CHUCNANG = `${QUANTRI_HETHONG}/cau-hinh-onoff-chuc-nang`;
const DANHMUC_KHACHHANG_HETHONG = `${QUANTRI_HETHONG}/khach-hang-he-thong`;
const CAUHINH_THOIGIAN_THANHTOAN = `${QUANTRI_HETHONG}/cau-hinh-thoi-gian-thanh-toan`;
const CAUHINH_LOAI_DE_NGHI = `${QUANTRI_HETHONG}/cau-hinh-loai-de-nghi`;
const CAUHINH_LUONG_KY = `${QUANTRI_HETHONG}/cau-hinh-luong-ky`;
const CAUHINH_UPLOAD_MEDIA = `${QUANTRI_HETHONG}/cau-hinh-upload`;
const CAUHINH_TRINHKY_VOFFICE = `${QUANTRI_HETHONG}/cau-hinh-trinh-ky`;
const CAUHINH_RUT_TIEN_COD = `${QUANTRI_HETHONG}/cau-hinh-rut-tien-cod`;

//Quản lý mặt hàng
const MERCHANDISE_MANAGEMENT='/merchandise';
const MERCHANDISE_MANAGEMENT_LIST_ITEM=`${MERCHANDISE_MANAGEMENT}/list-item`;
const MERCHANDISE_MANAGEMENT_MENU=`${MERCHANDISE_MANAGEMENT}/menu`;
const MERCHANDISE_MANAGEMENT_CATEGORY_ITEM=`${MERCHANDISE_MANAGEMENT}/category-item`;
const MERCHANDISE_MANAGEMENT_GROUP_ITEM=`${MERCHANDISE_MANAGEMENT}/group-item`;
const MERCHANDISE_MANAGEMENT_COMBO=`${MERCHANDISE_MANAGEMENT}/combo`


//Quản lý chi phí
const COST_MANAGEMENT = '/cost-management';
const COST_MANAGEMENT_PLAN = `${COST_MANAGEMENT}/plan`;
const COST_MANAGEMENT_CONFIRM_PLAN = `${COST_MANAGEMENT}/confirm-plan`;
const COST_MANAGEMENT_AIR_TICKET = `${COST_MANAGEMENT}/air-ticket`;
const COST_MANAGEMENT_PAYMENT = `${COST_MANAGEMENT}/payment`;
// const COST_MANAGEMENT_SUMMARY_REQUEST = `${COST_MANAGEMENT}/summary-request`;
const COST_MANAGEMENT_TRANSFER_REQUEST = `${COST_MANAGEMENT}/transfer-request`;
const COST_MANAGEMENT_QUOTA = `${COST_MANAGEMENT}/quota`;
const COST_MANAGEMENT_QUOTA_AIRPORT = `${COST_MANAGEMENT}/quota/airport`;
const COST_MANAGEMENT_QUOTA_HOTEL = `${COST_MANAGEMENT}/quota/hotel`;
const COST_MANAGEMENT_QUOTA_LIVING = `${COST_MANAGEMENT}/quota/living`;
const COST_MANAGEMENT_QUOTA_MOVING = `${COST_MANAGEMENT}/quota/moving`;
const COST_MANAGEMENT_QUOTA_LIVING_COST = `${COST_MANAGEMENT}/quota/living-cost`;
const COST_MANAGEMENT_SETTING = `${COST_MANAGEMENT}/setting`;
const COST_MANAGEMENT_USER = `${COST_MANAGEMENT}/setting/user`;
const COST_MANAGEMENT_DEPARTMENT = `${COST_MANAGEMENT}/setting/department`;
const COST_MANAGEMENT_COUNTRY = `${COST_MANAGEMENT}/setting/country`;
const COST_MANAGEMENT_PROVINCE = `${COST_MANAGEMENT}/setting/province`;
const COST_MANAGEMENT_DISTRICT = `${COST_MANAGEMENT}/setting/district`;
const COST_MANAGEMENT_WARD = `${COST_MANAGEMENT}/setting/ward`;
const COST_MANAGEMENT_AIRPORT = `${COST_MANAGEMENT}/setting/airport`;
const COST_MANAGEMENT_VEHICLE = `${COST_MANAGEMENT}/setting/vehicle`;
const COST_MANAGEMENT_VEHICLE_GROUP = `${COST_MANAGEMENT}/setting/vehicle-group`;
const COST_MANAGEMENT_SERVICE = `${COST_MANAGEMENT}/setting/service`;
const COST_MANAGEMENT_SERVICE_GROUP = `${COST_MANAGEMENT}/setting/service-group`;
const COST_MANAGEMENT_POSITION = `${COST_MANAGEMENT}/setting/position`;
const COST_MANAGEMENT_POSITION_GROUP = `${COST_MANAGEMENT}/setting/position-group`;
const COST_MANAGEMENT_POLICY_LIMIT = `${COST_MANAGEMENT}/setting/policy-limit`;
const COST_MANAGEMENT_PURPOSE = `${COST_MANAGEMENT}/setting/purpose`;
const COST_MANAGEMENT_DISTANCE_QUOTA = `${COST_MANAGEMENT}/setting/distance-quota`;
const COST_MANAGEMENT_GUEST_HOUSE = `${COST_MANAGEMENT}/setting/guest-house`;
const COST_MANAGEMENT_ROOM = `${COST_MANAGEMENT}/setting/room`;

const COST_MANAGEMENT_VOFFICE = `${COST_MANAGEMENT}/setting/voffice`;
const COST_MANAGEMENT_PLAN_REPORT = `${COST_MANAGEMENT}/plan-report`;
const COST_MANAGEMENT_PLAN_SYNTHETIC = `${COST_MANAGEMENT}/plan-synthetic`;
const COST_MANAGEMENT_PLAN_DELAY_DAY = `${COST_MANAGEMENT}/setting/delay-day`;
const COST_MANAGEMENT_INVOICE_COST_CONFIG = `${COST_MANAGEMENT}/setting/invoice-cost-config`;

const routesMap = {
  HOME,
  LOGIN,
  NOT_FOUND,
  REGISTER,

  /**
   * danh mục cấu hình
   */
  CATEGORY,
  PROVINCE,
  DISTRICT,
  WARD,
  ICE_LEVEL,
  SUGAR_LEVEL,
  SIZE_LEVEL,
  TOPPING,
  TEA_LEVEL,
  JOB,
  BUSSINESS_TYPE,
  UNIT,

  /**
   * Cấu hình QR
   */
  QR,
  QR_CONFIG,
  QR_ORDER,
  /**
   * Quản trị hệ thống
   */
  QUANTRI_HETHONG,
  QUANLY_CHUCNANG,
  QUANLY_CAUHINH,
  CAUHINH_LUINGAY_QLCP,
  CAUHINH_THONGTIN_CHI,
  CAUHINH_THONGTIN_CHI_BANK,
  CAUHINH_THONGTIN_CHI_BANK2,
  CAUHINH_THONGTIN_CHI_LIMIT,
  CAUHINH_MAIL_NGANHANG,
  CAUHINH_THANHTOAN_QR,
  CAUHINH_CAN_TRU_V2,
  CAUHINH_ONOFF_CHUCNANG,
  DANHMUC_KHACHHANG_HETHONG,
  CAUHINH_THOIGIAN_THANHTOAN,
  CAUHINH_LOAI_DE_NGHI,
  CAUHINH_LUONG_KY,
  CAUHINH_UPLOAD_MEDIA,
  CAUHINH_TRINHKY_VOFFICE,
  CAUHINH_RUT_TIEN_COD,
  DANHMUC_CAUHINH,
  KHAIBAO_HANMUC_CONGNO,
  /**
   * Quảnl lý chi phí
   */
  COST_MANAGEMENT,
  COST_MANAGEMENT_PLAN,
  COST_MANAGEMENT_CONFIRM_PLAN,
  COST_MANAGEMENT_AIR_TICKET,
  COST_MANAGEMENT_PAYMENT,
  COST_MANAGEMENT_TRANSFER_REQUEST,
  COST_MANAGEMENT_QUOTA,
  COST_MANAGEMENT_SETTING,
  COST_MANAGEMENT_QUOTA_AIRPORT,
  COST_MANAGEMENT_QUOTA_HOTEL,
  COST_MANAGEMENT_QUOTA_LIVING,
  COST_MANAGEMENT_QUOTA_MOVING,
  COST_MANAGEMENT_QUOTA_LIVING_COST,

  COST_MANAGEMENT_USER,
  COST_MANAGEMENT_DEPARTMENT,
  COST_MANAGEMENT_COUNTRY,
  COST_MANAGEMENT_PROVINCE,
  COST_MANAGEMENT_DISTRICT,
  COST_MANAGEMENT_WARD,
  COST_MANAGEMENT_AIRPORT,
  COST_MANAGEMENT_VEHICLE,
  COST_MANAGEMENT_VEHICLE_GROUP,
  COST_MANAGEMENT_SERVICE,
  COST_MANAGEMENT_SERVICE_GROUP,
  COST_MANAGEMENT_POSITION,
  COST_MANAGEMENT_POSITION_GROUP,
  COST_MANAGEMENT_POLICY_LIMIT,
  COST_MANAGEMENT_PURPOSE,
  COST_MANAGEMENT_DISTANCE_QUOTA,
  COST_MANAGEMENT_GUEST_HOUSE,
  COST_MANAGEMENT_ROOM,
  COST_MANAGEMENT_VOFFICE,
  COST_MANAGEMENT_PLAN_REPORT,
  COST_MANAGEMENT_PLAN_SYNTHETIC,
  COST_MANAGEMENT_PLAN_DELAY_DAY,
  COST_MANAGEMENT_INVOICE_COST_CONFIG,

  /*QUAN LY MAT HANG*/
  MERCHANDISE_MANAGEMENT,
  MERCHANDISE_MANAGEMENT_LIST_ITEM,
  MERCHANDISE_MANAGEMENT_MENU,
  MERCHANDISE_MANAGEMENT_CATEGORY_ITEM,
  MERCHANDISE_MANAGEMENT_GROUP_ITEM,
  MERCHANDISE_MANAGEMENT_COMBO






};

export default routesMap;
