/* eslint-disable @typescript-eslint/no-explicit-any */
import produce from 'immer';
import { createActionTypeOnSuccess } from 'redux-unfold-saga';
import { AppAction } from 'utils/types';
import {
  AUTH_GET_INFO_USER_SSO,
  AUTH_GET_POST_BY_USER,
  AUTH_GET_ROLE_BY_USER,
  AUTH_SET_ACCESS_TOKEN,
  AUTH_SET_LIST_PERMISSION_V2,
  AUTH_SET_POST_OFFICE_SELECTED,
  //REGISTER
  AUTH_REGISTER_FAILURE,
  AUTH_REGISTER_SUCCESS,
  AUTH_REGISTER_REQUEST,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGIN_FAILURE,
} from './actions';

interface AuthState {
  accessToken: string;
  userInfoSSO?: Auth.UserInfo;
  listPostOffice: Auth.PostOfiiceByUser[];
  selectedPO?: Auth.PostOfiiceByUser;
  listRoleByUser: Auth.RoleUser[];
  listPermission: Auth.IPermission[];
}

const initialState: AuthState = {
  accessToken: '',
  listPostOffice: [],
  listRoleByUser: [],
  listPermission: [],
};

const authReducer = (state = initialState, action: AppAction): AuthState => {
  return produce(state, (draftState) => {
    switch (action.type) {
      case AUTH_GET_INFO_USER_SSO:
        console.log('AUTH_GET_INFO_USER_SSO recducer', action.payload);
        draftState.userInfoSSO = action.payload;
        return draftState;
      case createActionTypeOnSuccess(AUTH_GET_POST_BY_USER):
        draftState.listPostOffice = action.payload;
        return draftState;

      case createActionTypeOnSuccess(AUTH_GET_ROLE_BY_USER):
        const roleNameUser = action.payload.map((item: Auth.IRoleUser) => ({ roleName: item.roleName }));
        draftState.listRoleByUser = roleNameUser;
        return draftState;

      case AUTH_SET_ACCESS_TOKEN:
        draftState.accessToken = action.payload;
        return draftState;

      case AUTH_SET_LIST_PERMISSION_V2:
        draftState.listPermission = action.payload;
        return draftState;

      case AUTH_SET_POST_OFFICE_SELECTED:
        draftState.selectedPO = action.payload;
        return draftState;

      case AUTH_REGISTER_REQUEST:
        return { ...state, loading: true, error: null };
      case AUTH_REGISTER_SUCCESS:
        return { ...state, loading: false, data: action.payload.data };
      case AUTH_REGISTER_FAILURE:
        return { ...state, loading: false, error: action.payload.error };

      case createActionTypeOnSuccess(AUTH_LOGIN_REQUEST):
        console.log(draftState, 'reducer', state, 'vs', action);
        draftState.userInfoSSO = action.payload;
        return draftState;
      case AUTH_LOGIN_SUCCESS:
        return { ...state, loading: false, data: action.payload };
      case AUTH_LOGIN_FAILURE:
        return { ...state, loading: false, error: action.payload.error };

      default:
        return draftState;
    }
  });
};

export default authReducer;
