import { ROLE_CONFIG } from 'common/phanQuyen';
import { AppRouteType, hasRoutePermission } from 'layouts/helper';
import pagesMap from 'layouts/pagesMap';
import routesMap from 'layouts/routesMap';
import { useMemo } from 'react';
import { smartOderRoute } from './smartOrderRoute';

const routes: AppRouteType[] = [
  /// 5 order
  {
    path: routesMap.HOME,
    component: pagesMap.TrangChu,
    rule: -1,
  },
  ...smartOderRoute,
];

export function useRoutes(listPermission: Auth.IPermission[]) {
  return useMemo(() => {
    //   if (ROLE_CONFIG.USE_ROLE) {
    //     return hasRoutePermission(routes, listPermission);
    //   }

    return routes;
  }, [listPermission]);
}
