export const LOGIN_PATH = {
  getOTPLogin: '/api/user/getLoginOTP',
  confirmOTPLogin: '/api/user/confirmLoginOTP_new',
  loginWithPhone: '/api/user/loginWithPhone',
  getPostByUser: '/api/user/postoffice_by_user',
  changePost: '/api/user/change_postoffice',
  loginMerchant:'/auth/merchant/login',



  //list Role
  listDumpRoles: '/api/utils/dumpRoles',
};
